import React, {
  type ComponentProps,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Checkbox,
  DateField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useForm,
} from "@adaptive/design-system/hooks";
import { formatDate, suffixify } from "@adaptive/design-system/utils";
import { type FileExport, getFileExportGroup } from "@api/exports";
import {
  getNonFieldErrors,
  handleErrors,
  isNonFieldErrors,
} from "@api/handle-errors";
import { useAppDispatch } from "@store/hooks";
import { useUserInfo } from "@store/user";
import { toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { PollError } from "@utils/poll";
import { z } from "zod";

import { DynamicActions, type DynamicActionsProps } from "../dynamic-actions";

const schema = z.object({
  date: z.object({ to: z.date().optional(), from: z.date().optional() }),
  type: z.record(z.string(), z.boolean()),
});

type Mode = "all" | "selection";

type SingleType = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Type = (SingleType & { data?: SingleType[] })[];

type Fields = z.infer<typeof schema>;

type Trigger = Exclude<
  ComponentProps<typeof DynamicActions>["trigger"],
  undefined
>;

type DownloadStatus = "idle" | "pending" | "succeeded" | "failed" | "timeout";

type OnDownloadProps = { mode: Mode; params: URLSearchParams };

type FileExportByStatus = { errors: FileExport[]; success: FileExport[] };

export type OnDownloadHandler = (
  props: OnDownloadProps
) => Promise<string | void>;

export type DownloadButtonProps = {
  mode?: Record<Mode, { enabled?: boolean; children?: string }>;
  type?: Type;
  size?: Trigger["size"];
  withDate?: boolean;
  onDownload?: OnDownloadHandler;
  "data-testid"?: string;
  initialType?: Record<string, boolean>;
  disabled?: Trigger["disabled"];
  variant?: Trigger["variant"];
  autoRun?: boolean;
};

const DEFAULT_TYPE: Type = [
  {
    label: "Transactions (XLSX)",
    value: "export_xlsx",
  },
  {
    label: "Backup (PDF)",
    value: "export_pdf",
  },
  {
    label: "Backup (ZIP)",
    value: "export_zip",
  },
];

const INITIAL_TYPE = { [DEFAULT_TYPE[0].value]: true };

const DEFAULT_MODE: DownloadButtonProps["mode"] = {
  all: { enabled: true, children: "Download all" },
  selection: { enabled: false, children: "Download selection" },
};

export const DownloadButton = ({
  mode = DEFAULT_MODE,
  type = DEFAULT_TYPE,
  size = "sm",
  withDate = false,
  onDownload = noop,
  "data-testid": testId,
  initialType = INITIAL_TYPE,
  disabled = false,
  variant = "solid",
  autoRun = false,
}: DownloadButtonProps) => {
  const [expanded, setExpanded] = useState(true);
  const dialog = useDialog({ lazy: true });

  const dispatch = useAppDispatch();

  const stopRef = useRef(noop);
  const lastRunRef = useRef(noop);

  const { user } = useUserInfo();

  const [files, setFiles] = useState<FileExport[]>([]);

  const [selectedMode, setSelectedMode] = useState<Mode>("all");

  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>("idle");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const initialValues = useDeepMemo(
    () => ({
      date: { to: undefined, from: undefined },
      type: initialType,
    }),
    [initialType]
  );

  const openChat = useEvent(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "download-button-fail" });
  });

  const onDialogClose = useEvent(async () => {
    await dialog.hide();
    setFiles([]);
    setErrorMessage("");
    setDownloadStatus("idle");
    form.reset();
    stopRef.current();
    setExpanded(true);
  });

  const onSubmit = useEvent(async ({ date, type }: Fields) => {
    setErrorMessage("");
    if (downloadStatus === "timeout") {
      setDownloadStatus("pending");
      return lastRunRef.current();
    }

    const hasSelectedType = Object.values(type).some((checked) => checked);

    if (!hasSelectedType) {
      return toast.error("You should select at least one type");
    }

    const params = new URLSearchParams();

    if (date.to && date.from) {
      params.append("date_after", formatDate(date.from, "yyyy-MM-dd"));
      params.append("date_before", formatDate(date.to, "yyyy-MM-dd"));
    }

    Object.entries(type).forEach(([key, value]) => {
      params.append(key, String(value));
    });

    setDownloadStatus("pending");

    let groupId;
    try {
      groupId = await onDownload({ mode: selectedMode, params });
    } catch (e) {
      setDownloadStatus("idle");
      if (isNonFieldErrors(e)) {
        const errors = getNonFieldErrors(e);
        setErrorMessage(errors?.[0]);
      } else {
        handleErrors(e);
      }
      return;
    }

    if (!groupId) {
      setDownloadStatus("failed");
      toast.error("Failed to export data");
      return;
    }

    const { run, stop } = getFileExportGroup(groupId);
    stopRef.current = stop;
    lastRunRef.current = async () => {
      try {
        const { files } = await run();
        const { errors, success } = files.reduce(
          (acc, file) => {
            if (file.status === "done") acc.success.push(file);

            if (file.status === "failed") acc.errors.push(file);

            return acc;
          },
          { errors: [], success: [] } as FileExportByStatus
        );

        errors.forEach((file) => toast.error(file.error));

        if (success.length > 0) {
          setFiles(success);
          toast.success(
            `Your downloads are ready! You will receive an email at ${user.email}`
          );
          setDownloadStatus("succeeded");
        } else {
          setDownloadStatus("failed");
        }
      } catch (e) {
        if (e instanceof PollError) return setDownloadStatus("timeout");

        setDownloadStatus("failed");
      }
    };
    lastRunRef.current();
  });

  const form = useForm<Fields>({
    schema,
    onSubmit,
    initialValues,
  });

  useEffect(() => {
    if (autoRun && dialog.isVisible && downloadStatus === "idle") {
      onSubmit(initialValues);
    }
  }, [onSubmit, dialog.isVisible, autoRun, downloadStatus, initialValues]);

  const actions = useMemo<Exclude<DynamicActionsProps["data"], undefined>>(
    () =>
      Object.entries(mode)
        .filter(([, { enabled }]) => enabled)
        .map(([key, { children }]) => ({
          onClick: () => {
            setSelectedMode(key as Mode);
            dialog.show();
          },
          children: children ?? DEFAULT_MODE[key as Mode].children,
        })),
    [mode, dialog]
  );

  useEffect(() => {
    return () => stopRef.current();
  }, []);

  return (
    <>
      <DynamicActions
        data={actions}
        data-testid={testId}
        trigger={{
          size,
          disabled,
          variant,
          color: "primary",
          children: "Download",
        }}
      />
      {dialog.isRendered && (
        <Dialog
          show={dialog.isVisible}
          variant="dialog"
          onClose={onDialogClose}
          size="auto"
        >
          <DialogHeader>Download</DialogHeader>
          <DialogContent>
            <Flex
              direction="column"
              as="form"
              justify="center"
              gap="sm"
              minWidth="480px"
              {...form.props}
            >
              <Flex direction="column" gap="md">
                {errorMessage && (
                  <Alert variant="error">
                    <AlertTitle>{errorMessage}</AlertTitle>
                    <AlertContent>
                      Please try again with fewer transactions or{" "}
                      <Link as="button" type="button" onClick={openChat}>
                        contact support
                      </Link>{" "}
                      for assistance
                    </AlertContent>
                  </Alert>
                )}

                {downloadStatus === "idle" && (
                  <>
                    {withDate && (
                      <DateField
                        mode="range"
                        placeholder="Filter by date"
                        data-skip-focusable=""
                        {...form.register({ name: "date", type: "range-date" })}
                      />
                    )}
                    <Flex gap="sm" direction="column">
                      {type.map((item) => (
                        <Flex direction="column" key={item.value}>
                          <Flex justify="space-between">
                            <Checkbox
                              key={item.value}
                              label={item.label}
                              placement="right"
                              disabled={item.disabled}
                              {...form.register({
                                name: `type.${item.value}`,
                                type: "boolean",
                                onChange: (checked) => {
                                  if (!checked && item.data) {
                                    Object.values(item.data).forEach(
                                      (value) => {
                                        form.setValue(
                                          `type.${value.value}`,
                                          false
                                        );
                                      }
                                    );
                                  }
                                },
                              })}
                              data-testid={suffixify(testId, item.value)}
                            />
                            {item && (item.data?.length ?? 0) > 0 && (
                              <Button
                                size="sm"
                                variant="ghost"
                                color="neutral"
                                onClick={() => setExpanded(!expanded)}
                              >
                                Select PDF pages{" "}
                                <Icon
                                  name={
                                    expanded ? "chevron-up" : "chevron-down"
                                  }
                                />
                              </Button>
                            )}
                          </Flex>
                          {expanded &&
                            (item.data ?? []).map((subItem) => (
                              <Flex
                                padding={["md", "none", "none", "4xl"]}
                                key={subItem.value}
                              >
                                <Checkbox
                                  key={subItem.value}
                                  label={subItem.label}
                                  placement="right"
                                  {...form.register({
                                    name: `type.${subItem.value}`,
                                    type: "boolean",
                                    onChange: (checked) => {
                                      if (checked) {
                                        form.setValue(
                                          `type.${item.value}`,
                                          true
                                        );
                                      }
                                    },
                                  })}
                                  data-testid={suffixify(testId, subItem.value)}
                                />
                              </Flex>
                            ))}
                        </Flex>
                      ))}
                    </Flex>
                  </>
                )}

                {downloadStatus === "pending" && (
                  <Alert variant="info">
                    <AlertTitle>
                      We are preparing your files now. This may take a few
                      minutes
                    </AlertTitle>
                    <AlertContent>
                      When they are ready you can download them directly here or
                      from an email that we will send to {user.email}
                    </AlertContent>
                  </Alert>
                )}

                {downloadStatus === "failed" && (
                  <Alert variant="error">
                    <AlertTitle>We encountered an issue</AlertTitle>
                    <AlertContent>
                      Please try again or{" "}
                      <Link as="button" type="button" onClick={openChat}>
                        contact support
                      </Link>{" "}
                      for assistance
                    </AlertContent>
                  </Alert>
                )}

                {downloadStatus === "timeout" && (
                  <Alert variant="warning">
                    <AlertTitle>
                      Download taking longer than expected
                    </AlertTitle>
                    <AlertContent>
                      Your files are still downloading. The process is taking
                      longer than expected due to their large size.
                      <br />
                      <br />
                      We will email them to {user.email} when they are ready.
                      Alternatively, if you prefer, you can try again and wait
                      for the download to be available.
                    </AlertContent>
                  </Alert>
                )}

                {downloadStatus === "succeeded" && (
                  <Alert variant="success">
                    <AlertTitle>Your files are ready to download</AlertTitle>
                    <AlertContent>
                      {files.map((fileExport, index) => (
                        <Fragment key={index}>
                          <Link
                            rel="noreferrer"
                            href={fileExport.document}
                            target="_blank"
                          >
                            {fileExport.document_title || "File Export"}
                          </Link>
                          {index < files.length - 1 && <br />}
                        </Fragment>
                      ))}
                    </AlertContent>
                  </Alert>
                )}
              </Flex>
            </Flex>
          </DialogContent>
          {downloadStatus !== "succeeded" && (
            <DialogFooter>
              <Button
                variant="text"
                color="neutral"
                onClick={onDialogClose}
                block
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form={form.id}
                block
                color={downloadStatus === "pending" ? "neutral" : "primary"}
                variant={downloadStatus === "pending" ? "ghost" : "solid"}
                disabled={downloadStatus === "pending"}
                data-testid={suffixify(testId, "download")}
              >
                {downloadStatus === "pending" && <Loader />}

                {downloadStatus === "failed" && "Try again"}

                {downloadStatus === "timeout" && "Keep waiting"}

                {downloadStatus === "idle" && "Download"}
              </Button>
            </DialogFooter>
          )}
        </Dialog>
      )}
    </>
  );
};
