import React from "react";
import {
  Avatar,
  Button,
  dialog,
  Flex,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@shared/api/handle-errors";

import {
  useSendLienWaiverReminderMutation,
  useUpdateLienWaiverRequestMutation,
} from "../api/api";
import { LIEN_WAIVER_STATUS, STRINGS } from "../constants/constants";
import { BillLienWaiver } from "../types";

import { BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverFieldRequestedProps = {
  billLienWaiver: BillLienWaiver;
} & Pick<BillLienWaiverFieldProps, "onRequestUpdate">;

export const BillLienWaiverFieldRequested = ({
  billLienWaiver,
  onRequestUpdate,
}: BillLienWaiverFieldRequestedProps) => {
  const { revisionEvent } = billLienWaiver;
  const revisionEventDate = parseDate(revisionEvent.createdAt, "iso");

  const [
    updateLienWaiverRequestMutation,
    { isLoading: requestUpdateIsLoading },
  ] = useUpdateLienWaiverRequestMutation();
  const [
    sendLienWaiverReminder,
    { isLoading: sendLienWaiverReminderIsLoading },
  ] = useSendLienWaiverReminderMutation();

  const isLoading = requestUpdateIsLoading || sendLienWaiverReminderIsLoading;

  const onCancelRequest = useEvent(() => {
    dialog.confirmation({
      title: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TITLE,
      message: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TEXT,
      action: {
        primary: {
          children: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRM,
          color: "error",
          onClick: async () => {
            try {
              await updateLienWaiverRequestMutation({
                id: billLienWaiver.id,
                status: LIEN_WAIVER_STATUS.NOT_REQUESTED,
              }).unwrap();
              onRequestUpdate();
              toast.success(STRINGS.CANCEL_LIEN_WAIVER_SUCCESS);
            } catch (error) {
              handleErrors(error);
            }
          },
        },
        secondary: {
          children: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_KEEP,
        },
      },
    });
  });

  const onSendReminder = useEvent(async () => {
    try {
      await sendLienWaiverReminder({ id: billLienWaiver.id }).unwrap();
      toast.success(STRINGS.REMINDER_SENT);
    } catch (error) {
      handleErrors(error);
    }
  });

  return (
    <Flex width="full" gap="md" align="flex-start">
      <Avatar name="paper-plane-top" color="info" size="lg" />
      <Flex align="flex-start" direction="column" gap="xs">
        <Text weight="bold">
          {parseStringCopy(STRINGS.LIEN_WAIVER_REQUESTED_TITLE, {
            receiver: billLienWaiver.vendor.displayName,
            sender: revisionEvent.author.fullName,
          })}
        </Text>
        <Text color="neutral-700" size="sm">
          {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
            date: formatDate(revisionEventDate, "MM/dd/yy"),
            time: formatDate(revisionEventDate, "hh:mma"),
          })}
        </Text>
        <Flex width="full" gap="md" padding={["md", "none", "none"]}>
          {billLienWaiver.status === LIEN_WAIVER_STATUS.REQUESTED && (
            <Button
              size="sm"
              onClick={onSendReminder}
              color="info"
              disabled={isLoading || !billLienWaiver.vendorReminderCanBeSent}
            >
              {STRINGS.LIEN_WAIVER_SEND_REMINDER}
            </Button>
          )}
          <Button
            size="sm"
            variant="ghost"
            color="info"
            disabled={isLoading}
            onClick={onCancelRequest}
          >
            {STRINGS.LIEN_WAIVER_CANCEL_REQUEST}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
