import React from "react";
import type { TableColumn } from "@adaptive/design-system";
import type { MarkupResponse } from "@api/budgets";

import {
  CostsActualColumn,
  CostsBudgetColumn,
  CostsChangeColumn,
  CostsRemainingColumn,
  CostsRevisedBudgetColumn,
  CostsUnpaidColumn,
  EmptyColumn,
  RevenuesChangeColumn,
  RevenuesInvoicedAmountColumn,
  RevenuesInvoiceRemainingAmountColumn,
  RevenuesOwnersAmountColumn,
  RevenuesRevisedBudgetColumn,
  TransactionsColumn,
  TransactionsColumnControl,
} from "./markups-components";

type MarkupsColumn = TableColumn<MarkupResponse>;

/**
 * Columns definition
 */
type GetMarkupColumnsProps = {
  hasChanges: boolean;
  hasCategories: boolean;
  hasExternalBudget: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getMarkupColumns = ({
  hasChanges,
  hasCategories,
  hasExternalBudget,
  hasExternalRevisedBudget,
}: GetMarkupColumnsProps) => {
  const info: MarkupsColumn = {
    id: "info",
    columns: [
      {
        id: "name",
        render: (row) => <TransactionsColumn {...row} />,
      },
    ],
  };

  if (hasCategories) {
    info.columns.push({
      id: "category",
      render: (row) => <TransactionsColumnControl {...row} />,
    });
  }

  const costs: MarkupsColumn = {
    id: "costs",
    columns: [
      {
        id: "budget",
        render: (row) => <CostsBudgetColumn {...row} />,
      },
    ],
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        render: (row) => <CostsChangeColumn {...row} />,
      },
      {
        id: "revisedBudget",
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    render: (row) =>
      row.budgetLine && <CostsActualColumn {...row.budgetLine} />,
  });

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costs.columns.push({
      id: "unpaid",
      render: (row) =>
        row.budgetLine && <CostsUnpaidColumn {...row.budgetLine} />,
    });
  }

  costs.columns.push({
    id: "costsRemaining",
    render: (row) =>
      row.budgetLine && <CostsRemainingColumn {...row.budgetLine} />,
  });

  const revenues: MarkupsColumn = {
    id: "revenues",
    columns: [],
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      render: () => <EmptyColumn />,
    });
  }

  if (hasExternalBudget) {
    revenues.columns.push({
      id: "ownersBudget",
      render: (row) => <RevenuesOwnersAmountColumn {...row} />,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "changes",
          render: (row) => <RevenuesChangeColumn {...row} />,
        },
        {
          id: "revisedBudget",
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      render: (row) => <RevenuesInvoicedAmountColumn {...row} />,
    },
    {
      id: "remaining",
      render: (row) => <RevenuesInvoiceRemainingAmountColumn {...row} />,
    }
  );

  return [info, costs, revenues];
};
