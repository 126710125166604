import React, {
  type ComponentProps,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Avatar,
  Button,
  dialog,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  LabelValueGroup,
  TableConfigurationButton,
  TableProvider,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Tag,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { useDialog } from "@adaptive/design-system/hooks";
import {
  useDeleteBudgetLineMutation,
  useGetBudgetLinesQuery,
} from "@api/budgets";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { invoicesApi, useDeleteInvoiceMutation } from "@api/invoices";
import type { BudgetLineItems, StoreCustomerCategoryResponse } from "@api/jobs";
import {
  exportJob,
  useBatchUpdateCustomerBudgetLinesCategoriesMutation,
} from "@api/jobs/jobs";
import { useUpdateQuickBooksErrorsMutation } from "@api/quickbooks";
import { AddChangeButton } from "@components/add-change-button";
import { ErrorAlert } from "@components/common/error-alert";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import { MainContent } from "@components/main";
import { Sticky, StickyMeasurer, StickyProvider } from "@components/sticky";
import { captureMessage } from "@sentry/browser";
import type { Option } from "@shared/types";
import {
  INVOICE_STRINGS,
  useJobActions,
  useJobBudgetSelectedLines,
  useJobInvoiceSelectedDraws,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { CalculateMarkupDialog } from "@src/jobs/detail-view/manage-markup-dialog";
import { useBudgetActions, useJobInfo } from "@store/jobs";
import { useDrawerVisibility } from "@store/ui";
import {
  BasePermissions,
  useClientInfo,
  useClientSettings,
  useUserInfo,
} from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { scrollMainTop } from "@utils/scroll-main-top";

import { BudgetsCategoriesCombobox } from "./budget/budgets-categories-combobox";
import { type ChangeDialogStep, ChangesDialog } from "./budget/changes-dialog";
import { Budget } from "./budget";
import { JobProgressBar } from "./budget-progress-bar";
import { Tab as InvoicesTab } from "./invoices";

const BILLING_TYPE = [
  { label: "Cost plus", value: "COST_PLUS" },
  { label: "Fixed price", value: "FIXED_PRICE" },
];

type StatusTab = "budget" | "invoice";

type LabelValueGroupData = Exclude<
  ComponentProps<typeof LabelValueGroup>["render"],
  undefined | Function // eslint-disable-line @typescript-eslint/ban-types
>[];

const BATCH_ERROR_MESSAGES: Record<string, string> = {
  "Cannot delete budget line that has an amount spent against it":
    "The following lines cannot be deleted because they have spent costs:",
  "Cannot delete budget line that has an amount invoiced against it":
    "The following lines cannot be deleted because they have drawn costs:",
  "Cannot delete budget line that has a changes applied":
    "The following lines cannot be deleted because they have changes applied:",
};

const UNCATEGORIZED_OPTION = {
  label: "Clear category",
  value: Symbol("UNCATEGORIZED").toString(),
};

const INITIAL_TYPE_V2 = {
  draw_schedule_pdf: true,
  draw_schedule: true,
  draw_schedule_by_category: false,
  draw_schedule_changes: true,
  draw_schedule_transaction_backup: false,
  draw_schedule_xlsx: true,
  draw_schedule_transaction_zip: false,
};

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

const AfterTabs = ({ value }: { value: string }) => {
  const { job } = useJobInfo();

  const dispatch = useDispatch();

  const addChangeDialog = useMultiStepDialog<ChangeDialogStep>({
    lazy: true,
    initialStep: "create-change",
  });

  const calculateMarkupDialog = useDialog({ lazy: true });

  const onShowCalculateMarkupDialog = useEvent(() => {
    calculateMarkupDialog.show();
    analytics.track("budgetBatchActions", {
      action: "show-calculate-markup-dialog",
      budgetLineIds: budgetSelectedLines.map((line) => line.id),
    });
  });

  const { realmId, client } = useClientInfo();

  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;

  const { canManage } = useJobPermissions();

  const [deleteInvoice] = useDeleteInvoiceMutation();

  const [deleteBudgetLine] = useDeleteBudgetLineMutation();

  const budgetSelectedLines = useJobBudgetSelectedLines();

  const invoiceSelectedDraws = useJobInvoiceSelectedDraws();

  const { categoriesEnabled, ownersAmountEnabled, changeTrackingEnabled } =
    useJobSettings();

  const { drawExportsV2Enabled } = useClientSettings();

  const [updateQuickBooksErrorsMutation] = useUpdateQuickBooksErrorsMutation();

  const { setBudgetSelectedLines, setInvoiceSelectedDraws } = useJobActions();

  const [batchUpdateCustomerBudgetLinesCategories] =
    useBatchUpdateCustomerBudgetLinesCategoriesMutation();

  const onBudgetDownload = useEvent<OnDownloadHandler>(async ({ params }) => {
    if (realmId) params.append("realm", String(realmId));

    return exportJob({ id: job.id, params });
  });

  const {
    data: budgetLines = EMPTY_BUDGET_LINES,
    isSuccess: isBudgetLinesSuccess,
  } = useGetBudgetLinesQuery({
    customerId: job.id,
  });

  const extraCategories = useMemo(() => [UNCATEGORIZED_OPTION], []);

  /**
   * @todo we need to remove this try catch as soon as we figure out the real issue behind `jobCostMethod.url` usage https://adaptive-real-estate.sentry.io/issues/4494809495/?environment=production&project=4503937907687424&query=is%3Aunresolved+level%3A%5Bfatal%2Cerror%5D+error.handled%3Afalse&referrer=issue-stream&stream_index=0
   */
  const jobCostMethodUrls = useMemo(() => {
    try {
      return budgetSelectedLines.map((line) => line.jobCostMethod.url);
    } catch (e) {
      captureMessage(`Error getting job cost method urls on job: ${e}`);
      return [];
    }
  }, [budgetSelectedLines]);

  const onDeleteLines = useEvent(async () => {
    const handler = async () => {
      const requests = budgetSelectedLines.map(async (line) => {
        try {
          await deleteBudgetLine({
            customerId: job.id,
            budgetLineId: line.id,
          }).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { jobCostMethodName: line.jobCostMethod.displayName },
            render: (message) => BATCH_ERROR_MESSAGES[message] ?? `${message}:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ jobCostMethodName: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, jobCostMethodName }) =>
          `${message}${isFirst ? "" : ","} ${jobCostMethodName}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(
          `${success} budget line${success > 1 ? "s" : ""} deleted!`
        );
      }

      analytics.track("budgetBatchActions", {
        action: "delete",
        budgetLineIds: budgetSelectedLines.map((line) => line.id),
      });

      setBudgetSelectedLines([]);
    };

    dialog.confirmation({
      title: `Delete ${budgetSelectedLines.length} budget line${
        budgetSelectedLines.length > 1 ? "s" : ""
      }?`,
      action: {
        primary: { color: "error", onClick: handler, children: "Delete" },
      },
    });
  });

  const isDisabledSyncErrors = invoiceSelectedDraws.some(
    (item) =>
      item.errors?.length === 0 || item.errors?.some((error) => error.isIgnored)
  );

  const onIgnoreSyncErrors = useEvent(async () => {
    const ids = invoiceSelectedDraws
      .map((item) => item.errors.map((error) => error.id))
      .flat();

    try {
      await updateQuickBooksErrorsMutation({ ids, isIgnored: true }).unwrap();
      dispatch(invoicesApi.util.invalidateTags(["Invoices"]));
      analytics.track("invoiceBatchActions", {
        action: "ignore-sync-errors",
        invoiceIds: invoiceSelectedDraws.map((draw) => draw.id),
      });
      toast.success(
        `${ids.length} Draw${
          ids.length > 1 ? "s" : ""
        } with sync errors ignored!`
      );
      setInvoiceSelectedDraws([]);
    } catch (e) {
      handleErrors(e);
    }
  });

  const onDeleteDraws = useEvent(() => {
    const handler = async () => {
      const requests = invoiceSelectedDraws.map(async (draw) => {
        try {
          await deleteInvoice({ id: draw.id }).unwrap();
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: { docNumber: draw.docNumber },
            render: (message) => `${message} on the following draws:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ docNumber: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, docNumber }) =>
          `${message}${isFirst ? "" : ","} #${docNumber}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }

      if (success) {
        toast.success(`${success} draw${success > 1 ? "s" : ""} deleted!`);
      }

      analytics.track("invoiceBatchActions", {
        action: "delete",
        invoiceIds: invoiceSelectedDraws.map((draw) => draw.id),
      });

      setInvoiceSelectedDraws([]);
    };

    dialog.confirmation({
      title: `Delete ${invoiceSelectedDraws.length} draw${
        invoiceSelectedDraws.length > 1 ? "s" : ""
      }?`,
      action: {
        primary: { color: "error", onClick: handler, children: "Delete" },
      },
    });
  });

  const onAddCategory = useEvent(
    async (category: StoreCustomerCategoryResponse) => {
      try {
        await batchUpdateCustomerBudgetLinesCategories({
          customerId: job.id,
          categoryId: category.id,
          budgetLines: budgetSelectedLines.map((line) => line.id),
        }).unwrap();

        toast.success(
          `${budgetSelectedLines.length} line${
            budgetSelectedLines.length > 1 ? "s" : ""
          } categorized as ${category.displayName}!`
        );
      } catch (e) {
        handleErrors(e);
      }
    }
  );
  const onAddChange = useEvent(() => {
    addChangeDialog.show();
  });

  const onChangeCategory = useEvent(async (_, option?: Option) => {
    if (!option) return;

    try {
      await batchUpdateCustomerBudgetLinesCategories({
        customerId: job.id,
        categoryId:
          option.value === UNCATEGORIZED_OPTION.value ? null : option.value,
        budgetLines: budgetSelectedLines.map((line) => line.id),
      }).unwrap();

      analytics.track("budgetBatchActions", {
        value: option.value,
        action: "category",
        budgetLineIds: budgetSelectedLines.map((line) => line.id),
      });

      toast.success(
        `${budgetSelectedLines.length} line${
          budgetSelectedLines.length > 1 ? "s" : ""
        } categorized as ${option.label}!`
      );
    } catch (e) {
      handleErrors(e);
    }
  });

  useEffect(() => {
    return () => {
      setBudgetSelectedLines([]);
      setInvoiceSelectedDraws([]);
    };
  }, [setBudgetSelectedLines, setInvoiceSelectedDraws]);

  if (value === "budget") {
    return (
      <Flex grow justify="flex-end">
        {addChangeDialog.isRendered && (
          <ChangesDialog
            mode="create"
            dialog={addChangeDialog}
            jobCostMethods={jobCostMethodUrls}
            onSubmitChange={addChangeDialog.hide}
          />
        )}
        {calculateMarkupDialog.isRendered && (
          <CalculateMarkupDialog
            dialog={calculateMarkupDialog}
            budgetLines={budgetSelectedLines}
          />
        )}
        {isBudgetLinesSuccess && budgetLines.length > 0 && (
          <Flex gap="md">
            {clientChangeTrackingEnabled && (
              <AddChangeButton jobCostMethods={jobCostMethodUrls} />
            )}
            {drawExportsV2Enabled && !drawExportsV2Enabled ? (
              <DownloadButton
                mode={{
                  all: { enabled: true, children: "Download" },
                  selection: { enabled: false },
                }}
                withDate
                onDownload={onBudgetDownload}
                data-testid="budget"
                initialType={INITIAL_TYPE_V2}
                type={[
                  {
                    label: "Draw schedule (PDF)",
                    value: "draw_schedule_pdf",
                    data: [
                      {
                        label: "Draw schedule",
                        value: "draw_schedule",
                      },
                      {
                        label: "Draw schedule (by category)",
                        value: "draw_schedule_by_category",
                      },
                      {
                        label: "Changes",
                        value: "draw_schedule_changes",
                      },
                      {
                        label: "Transaction backup",
                        value: "draw_schedule_transaction_backup",
                      },
                    ],
                  },
                  {
                    label: "Draw schedule (XLSX)",
                    value: "draw_schedule_xlsx",
                  },
                  {
                    label: "Transaction backup (ZIP)",
                    value: "draw_schedule_transaction_zip",
                  },
                ]}
              />
            ) : (
              <DownloadButton
                mode={{
                  all: { enabled: true, children: "Download" },
                  selection: { enabled: false },
                }}
                withDate
                onDownload={onBudgetDownload}
                data-testid="budget"
                type={[
                  {
                    label: "Budget + transactions (XLSX)",
                    value: "export_xlsx",
                  },
                  {
                    label: "Transaction backup (PDF)",
                    value: "export_pdf",
                  },
                  {
                    label: "Transaction backup (ZIP)",
                    value: "export_zip",
                  },
                ]}
              />
            )}
            {budgetSelectedLines.length > 0 ? (
              categoriesEnabled ||
              changeTrackingEnabled ||
              ownersAmountEnabled ? (
                <Dropdown placement="bottom-end">
                  <DropdownTrigger
                    as={Button}
                    size="sm"
                    data-testid="budgets-actions-trigger"
                  >
                    Actions
                    <Icon name="ellipsis-vertical" variant="solid" />
                  </DropdownTrigger>
                  <DropdownList>
                    <Tooltip
                      message={
                        !canManage ? "You don't have permission to do this" : ""
                      }
                      placement="left"
                    >
                      <DropdownItem
                        color="error"
                        disabled={!canManage}
                        onClick={onDeleteLines}
                      >
                        Delete lines
                      </DropdownItem>
                    </Tooltip>
                    {ownersAmountEnabled && (
                      <>
                        <Tooltip
                          message={
                            !canManage
                              ? "You don't have permission to do this"
                              : !ownersAmountEnabled
                                ? "Enable external budget in the job settings to add markup"
                                : ""
                          }
                          placement="left"
                        >
                          <DropdownItem
                            disabled={!canManage || !ownersAmountEnabled}
                            onClick={onShowCalculateMarkupDialog}
                          >
                            Calculate external budget
                          </DropdownItem>
                        </Tooltip>
                      </>
                    )}
                    {changeTrackingEnabled && (
                      <Tooltip
                        message={
                          !canManage
                            ? "You don't have permission to do this"
                            : !changeTrackingEnabled
                              ? "Enable change tracking in the job settings to add change"
                              : ""
                        }
                        placement="left"
                      >
                        <DropdownItem
                          disabled={!canManage || !changeTrackingEnabled}
                          onClick={onAddChange}
                        >
                          Add a change
                        </DropdownItem>
                      </Tooltip>
                    )}
                    {categoriesEnabled && (
                      <DropdownItem>
                        <BudgetsCategoriesCombobox
                          value=""
                          extra={extraCategories}
                          portal
                          onChange={onChangeCategory}
                          onAddCategory={onAddCategory}
                        />
                      </DropdownItem>
                    )}
                  </DropdownList>
                </Dropdown>
              ) : (
                <Tooltip
                  message={
                    !canManage ? "You don't have permission to do this" : ""
                  }
                  placement="left"
                >
                  <Button
                    size="sm"
                    color="error"
                    onClick={onDeleteLines}
                    disabled={!canManage}
                  >
                    Delete lines
                  </Button>
                </Tooltip>
              )
            ) : null}
          </Flex>
        )}
      </Flex>
    );
  }

  if (value === "invoice") {
    return (
      <Flex grow gap="md" justify="flex-end">
        <TableConfigurationButton size="sm" />
        {invoiceSelectedDraws.length > 0 && (
          <Dropdown>
            <DropdownTrigger
              size="sm"
              as={Button}
              data-testid="invoices-actions-trigger"
            >
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              <Tooltip
                message={
                  isDisabledSyncErrors
                    ? "You can only ignore sync errors on draws that have sync errors"
                    : ""
                }
                placement="left"
              >
                <DropdownItem
                  onClick={onIgnoreSyncErrors}
                  disabled={isDisabledSyncErrors}
                  data-testid="ignore-sync-errors"
                >
                  Ignore sync errors
                </DropdownItem>
              </Tooltip>
              <DropdownItem
                color="error"
                onClick={onDeleteDraws}
                data-testid="delete-draws"
              >
                Delete draws
              </DropdownItem>
            </DropdownList>
          </Dropdown>
        )}
      </Flex>
    );
  }

  return null;
};

export const DetailView = () => {
  const { hasPermission } = useUserInfo();

  const [searchParams, setSearchParams] = useSearchParams({
    status: "budget",
  });

  const currentTab = (searchParams.get("status") ?? "budget") as StatusTab;

  const { client } = useClientInfo();

  const { refetchJob } = useJobActions();

  const { setVisible } = useDrawerVisibility("job");

  const { job, status } = useJobInfo();

  const { data: budgetLines, isSuccess } = useGetBudgetLinesQuery({
    customerId: job.id,
  });

  const canViewAllInvoices = hasPermission(BasePermissions.VIEW_ALL_INVOICES);

  const { setBudgetLines } = useBudgetActions();

  const { canManage } = useJobPermissions();

  const canSetQBClass = client?.settings.can_set_qb_class ?? false;
  const canSetLocation = client?.settings.can_set_location ?? false;

  const clientCategoriesEnabled = client?.settings.categories_enabled ?? false;
  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;
  const clientExternalBudgetEnabled =
    client?.settings.external_budget_enabled ?? false;

  const editJob = useCallback(() => setVisible(true), [setVisible]);

  const info = useMemo(() => {
    const first: LabelValueGroupData = [];
    const second: LabelValueGroupData = [];
    const third: LabelValueGroupData = [];

    if (status !== "loaded") return { first, second };

    first.push({
      label: "Default BA",
      value: job.bank_account?.name ?? "-",
    });

    first.push({
      label: "Billing type",
      value:
        BILLING_TYPE.find((type) => type.value === job.billing_type)?.label ||
        "-",
    });

    if (canSetQBClass) {
      first.push({
        label: "Class",
        value: job.qb_class ? job.qb_class.display_name : "-",
      });
    }

    let people = "Everyone";

    if (job.restricted_to_users.length > 0) {
      people = job.restricted_to_users.map((user) => user.full_name).join(", ");
    }

    if (job.parent_display_name) {
      first.push({
        label: "Is a sub-job of",
        value: job.parent_display_name,
      });
    }

    if (clientCategoriesEnabled) {
      third.push({
        label: "Categorization",
        value: job.categories_enabled ? "On" : "Off",
      });
    }

    if (clientChangeTrackingEnabled) {
      third.push({
        label: "Change tracking",
        value: job.change_tracking_enabled ? "On" : "Off",
      });
    }

    if (clientExternalBudgetEnabled) {
      third.push({
        label: "Multiple budgets",
        value: job.owners_amount_enabled ? "On" : "Off",
      });
    }

    second.push({ label: "People", value: people });

    second.push({ label: "Phone", value: job.phone_number ?? "-" });

    let address = "-";

    const addressBilling = job.addresses.find(
      (addr) => addr.type === "Billing"
    );

    if (addressBilling) {
      address =
        [
          addressBilling.line1,
          addressBilling.city,
          addressBilling.state,
          addressBilling.postal_code,
        ]
          .filter((addressData) => addressData)
          .join(", ") || "-";
    }

    second.push({ label: "Address", value: address });
    if (canSetLocation && job.location) {
      second.push({ label: "Location", value: job.location.display_name });
    }

    return { first, second, third };
  }, [
    canSetLocation,
    canSetQBClass,
    clientCategoriesEnabled,
    clientChangeTrackingEnabled,
    clientExternalBudgetEnabled,
    job.addresses,
    job.bank_account?.name,
    job.billing_type,
    job.categories_enabled,
    job.change_tracking_enabled,
    job.location,
    job.owners_amount_enabled,
    job.parent_display_name,
    job.phone_number,
    job.qb_class,
    job.restricted_to_users,
    status,
  ]);

  const onTabChange = useEvent(async (status: string) => {
    scrollMainTop(0);
    setSearchParams({ status });
  });

  useEffect(() => {
    if (budgetLines && isSuccess) {
      setBudgetLines(budgetLines);
    }
  }, [budgetLines, isSuccess, setBudgetLines]);

  return (
    <>
      <MainContent>
        <Flex direction="column" gap="2xl" shrink={false}>
          {(job.errors || []).length > 0 && (
            <ErrorAlert
              data={job.errors!}
              onChange={refetchJob}
              objectType="Customer"
            />
          )}

          <Flex direction="row" gap="4xl" width="full" shrink={false}>
            <Flex direction="column" shrink={false}>
              <Avatar
                name={job.display_name_without_company}
                size={140}
                color={job.active ? "success" : "neutral"}
              />
            </Flex>
            <Flex direction="column" gap="md" width="full">
              <Flex gap="lg" direction="row" align="center">
                <Text weight="bold" size="2xl" data-testid="job-display-name">
                  {job.display_name}
                </Text>
                {!job.active && <Tag>Inactive</Tag>}
              </Flex>
              <Flex gap="3xl" align="flex-start">
                <LabelValueGroup data={info.first} compact />
                <LabelValueGroup data={info.second} compact />
                <LabelValueGroup data={info.third} compact />
              </Flex>
              <Flex>
                <Button
                  onClick={editJob}
                  variant="ghost"
                  size="sm"
                  disabled={!canManage}
                >
                  Edit
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <JobProgressBar />
          <div>
            <TableProvider id={`job-${currentTab}-table`}>
              <StickyProvider>
                <Tabs value={currentTab} onChange={onTabChange}>
                  <Sticky
                    style={{
                      paddingTop: "var(--spacing-xl)",
                      paddingBottom: "var(--spacing-2xl)",
                    }}
                  >
                    <TabsList>
                      <TabsTab value="budget">Budget</TabsTab>
                      {canViewAllInvoices && (
                        <TabsTab value="invoice">
                          {INVOICE_STRINGS.INVOICES}
                        </TabsTab>
                      )}
                      <AfterTabs value={currentTab} />
                    </TabsList>
                  </Sticky>
                  <StickyMeasurer>
                    <TabsPanel>
                      {currentTab === "budget" && <Budget />}
                      {currentTab === "invoice" && canViewAllInvoices && (
                        <InvoicesTab />
                      )}
                    </TabsPanel>
                  </StickyMeasurer>
                </Tabs>
              </StickyProvider>
            </TableProvider>
          </div>
        </Flex>
      </MainContent>
    </>
  );
};
