import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  dialog,
  Flex,
  Icon,
  Loader,
  MultipleTable,
  type MultipleTableFooter,
  type MultipleTableHeader,
  MultipleTableProps,
  MultipleTableWidths,
  TableSelectAddon,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import {
  dotObject,
  formatCurrency,
  isEqual,
} from "@adaptive/design-system/utils";
import type { BudgetLinesBulkCreatePayload } from "@api/budgets";
import {
  type MarkupResponse,
  useDeleteBudgetLineMarkupsMutation,
  useDeleteBudgetLineMutation,
  useDeleteMarkupMutation,
  useGetBudgetLinesQuery,
  useUpdateBudgetLineMutation,
} from "@api/budgets";
import { useAddBudgetLinesToJobMutation } from "@api/budgets/budget-lines";
import { budgetLineUpdateRequestPayloadSchema } from "@api/budgets/request";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import OneSchemaImporter, {
  type OneSchemaImporterProps,
} from "@oneschema/react";
import {
  useJobActions,
  useJobBudgetSelectedLines,
  useJobMarkups,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { CURRENCY_FORMAT } from "@src/jobs/constants";
import { AddBudgetLineDialogButton } from "@src/jobs/detail-view/add-budget-line-dialog";
import { ChangeDialogStep } from "@src/jobs/detail-view/budget/changes-dialog";
import {
  AddMarkupLineButton,
  EditFixedMarkupDialog,
} from "@src/jobs/detail-view/manage-markup-dialog";
import { useClientInfo } from "@src/shared/store/user";
import { useBudgetActions, useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";
import { getItemUrls } from "@utils/get-cost-code-account-values";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";

import type { MarkupDialogSteps } from "../../manage-markup-dialog/types";
import { ChangesDialog } from "../changes-dialog";
import { DrawnToDateDialog } from "../drawn-to-date-dialog";
import { EditInlineMarkupDialog } from "../edit-inline-markup-dialog";
import { TransactionDialog } from "../transaction-dialog";

import { BudgetsTableContext } from "./budgets-table-context";
import { useTableTotals } from "./hooks";
import { getLineColumns } from "./lines";
import { getIsDeletable } from "./lines";
import { getMarkupColumns } from "./markups";
import {
  CurriedEditLineItemHandler,
  CurriedLineItemHandler,
  CurriedOnDeleteMarkupHandler,
  CurriedOnOpenOneSchemaHandlers,
  CurriedOptionalLineItemHandler,
  LineItemHandler,
  OnEditFixedAmountMarkupHandler,
  UpdateCategoryHandler,
} from "./types";

type OneSchemaRecord = {
  budget: number;
  costCode: string;
  category?: string;
  changeAmount?: number;
};

type OnOneSchemaSuccessHandler = (data: { records: OneSchemaRecord[] }) => void;

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

export const BudgetsTable = memo(() => {
  const { job } = useJobInfo();

  const markups = useJobMarkups();

  const [searchParams] = useSearchParams();

  const changeParam = searchParams.get("change");

  const locationParam = searchParams.get("location");

  const { canManage } = useJobPermissions();

  const budgetSelectedLines = useJobBudgetSelectedLines();

  const { categoriesEnabled, changeTrackingEnabled, ownersAmountEnabled } =
    useJobSettings();

  const { client } = useClientInfo();

  const clientExternalRevisedBudgetEnabled =
    client?.settings.external_revised_budget_enabled ?? false;

  const { refetchJob, setBudgetSelectedLines } = useJobActions();

  const [currentMarkup, setCurrentMarkup] = useState<
    MarkupResponse | undefined
  >();

  const [currentBudgetLine, setCurrentBudgetLine] = useState<
    BudgetLineItems | undefined
  >();

  const { updateBudgedLockedStatus, copyBuilderBudgetToRevisedBudget } =
    useBudgetActions();

  const transactionDialog = useDialog({ lazy: true });
  const drawnToDateDialog = useDialog({ lazy: true });
  const changesDialog = useMultiStepDialog<ChangeDialogStep>({
    lazy: true,
    initialStep: "view-changes",
  });
  const changesDialogShow = useEvent(() => changesDialog.show());
  const drawnToDateDialogShow = useEvent(() => drawnToDateDialog.show());

  const markupEditInlineDialog = useDialog({ lazy: true });
  const markupEditFixedAmountDialog = useMultiStepDialog<MarkupDialogSteps>({
    lazy: true,
    initialStep: "markup-form",
  });

  const [deleteMarkup] = useDeleteMarkupMutation();

  const [updateBudgetLine] = useUpdateBudgetLineMutation();

  const [deleteBudgetLine] = useDeleteBudgetLineMutation();

  const { data: lines = EMPTY_BUDGET_LINES, isLoading: isLoadingLines } =
    useGetBudgetLinesQuery({
      customerId: job.id,
    });

  const [deleteBudgetLineMarkups] = useDeleteBudgetLineMarkupsMutation();

  // OneSchema
  const [budgetImportColumn, setBudgetImportColumn] = useState<
    "builderAmount" | "ownersAmount"
  >("builderAmount");
  const [oneSchemaIsOpen, setOneSchemaIsOpen] = useState(false);
  const [oneSchemaIsLoading, setOneSchemaIsLoading] = useState(false);
  const lineItems = useJobsCostCodeAccountSimplified();
  const [addBudgetLinesToJob, { isLoading: budgetIsLoading }] =
    useAddBudgetLinesToJobMutation();

  const columnsToAdd = useMemo(() => [], []);

  const columnsToRemove = useMemo(() => [], []);

  const columnsToUpdate = useMemo(
    () => [
      {
        key: "costCode",
        validation_options: {
          values: Array.from(
            new Set(
              lineItems.data.map((item) => {
                if (window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED) {
                  return `${item.label} - ${item.groupLabel}`;
                }
                return item.label;
              })
            )
          ),
        },
      },
    ],
    [lineItems.data]
  );

  const templateOverrides = useMemo(
    () => ({
      columns_to_add: columnsToAdd,
      columns_to_update: columnsToUpdate,
      columns_to_remove: columnsToRemove,
      validation_hooks_to_add: [],
    }),
    [columnsToAdd, columnsToRemove, columnsToUpdate]
  );

  const curriedOnOpenOneSchema = useCallback<CurriedOnOpenOneSchemaHandlers>(
    (column) => () => {
      analytics.track("budgetImport", { column, customerId: job.id });
      setBudgetImportColumn(column);
      setOneSchemaIsLoading(true);
      setOneSchemaIsOpen(true);
    },
    [job.id]
  );

  const onOneSchemaLaunched = useEvent(() => setOneSchemaIsLoading(false));

  const onOneSchemaSuccess = useEvent<OnOneSchemaSuccessHandler>(
    async (data) => {
      if (lineItems.status !== "success") return;
      const budgetLines = data.records.reduce(
        (acc, record) => {
          const costCode = lineItems.data.find(
            (item) =>
              item.label ===
              record.costCode.replace(/ - (Cost code|Account)$/, "")
          );

          if (!costCode) return acc;

          const itemId = parseRefinementIdFromUrl(costCode.value);

          if (!itemId) return acc;

          const existingBudgetLine = acc.find((line) => {
            if ("groupLabel" in costCode && costCode.groupLabel) {
              return (
                (line.itemId === itemId &&
                  costCode.groupLabel === "Cost code") ||
                (line.accountId === itemId && costCode.groupLabel === "Account")
              );
            }
            return line.itemId === itemId;
          });

          if (existingBudgetLine) {
            if (!existingBudgetLine[budgetImportColumn]) {
              existingBudgetLine[budgetImportColumn] = Number(
                Number(record.budget).toFixed(2)
              );
            } else {
              existingBudgetLine[budgetImportColumn] = sum(
                existingBudgetLine[budgetImportColumn] || 0,
                record.budget
              );
            }
          } else {
            const lineItemId = getItemUrls(costCode.value);

            acc.push({
              itemId: lineItemId.item ? itemId : undefined,
              accountId: lineItemId.account ? itemId : undefined,
              [budgetImportColumn]: Number(Number(record.budget).toFixed(2)),
              ...(record.category
                ? { categoryDisplayName: record.category }
                : {}),
              ...(record.changeAmount
                ? { changeAmount: record.changeAmount }
                : {}),
            });
          }

          return acc;
        },
        [] as BudgetLinesBulkCreatePayload["budgetLines"]
      );

      try {
        await addBudgetLinesToJob({ budgetLines, customerId: job.id }).unwrap();
        await refetchJob();
        toast.success(`Budget imported`);
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const onOneSchemaError = useEvent<
    Exclude<OneSchemaImporterProps["onError"], undefined>
  >((error) => {
    toast.error(error.message);
  });

  const onRequestClose = useEvent(() => {
    setOneSchemaIsOpen(false);
  });

  const hasLines = lines.length > 0;

  const header = useMemo<MultipleTableHeader>(() => ({ offset: 80 }), []);

  const widths = useMemo(() => {
    const transactions: MultipleTableWidths = ["fill"];

    if (categoriesEnabled) transactions.push(250);

    const costs: MultipleTableWidths = [175];

    if (changeTrackingEnabled) {
      /* add for Changes column */
      costs.push(160);

      /* add for Revised Budget column */
      costs.push(160);
    }

    costs.push(170);

    if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) costs.push(120);

    costs.push(155);

    const revenues: MultipleTableWidths = [];

    if (window.BUDGET_MARKUP_COLUMN_ENABLED) revenues.push(150);

    if (ownersAmountEnabled) {
      revenues.push(230);

      if (changeTrackingEnabled && clientExternalRevisedBudgetEnabled) {
        revenues.push(160);
        revenues.push(160);
      }
    }

    revenues.push(160);
    revenues.push(140);

    const values = [transactions, costs, revenues];

    return values as MultipleTableWidths;
  }, [
    categoriesEnabled,
    changeTrackingEnabled,
    clientExternalRevisedBudgetEnabled,
    ownersAmountEnabled,
  ]);

  const lineSelectIsSelectable = useCallback(() => canManage, [canManage]);

  const lineSelectOnChange = useEvent((lines: BudgetLineItems[]) =>
    setBudgetSelectedLines((previousBudgetSelectedLines) =>
      isEqual(previousBudgetSelectedLines, lines)
        ? previousBudgetSelectedLines
        : lines
    )
  );

  const lineSelect = useMemo<TableSelectAddon<BudgetLineItems>>(
    () => ({
      value: budgetSelectedLines,
      onChange: lineSelectOnChange,
      isSelectable: lineSelectIsSelectable,
    }),
    [lineSelectOnChange, budgetSelectedLines, lineSelectIsSelectable]
  );

  const lineEmptyState = useMemo(
    () => ({
      title: "Create budget",
      action: (
        <Flex gap="md">
          <AddBudgetLineDialogButton
            size="md"
            variant="solid"
            tooltipPlacement="top"
          />
          <Button
            disabled={!canManage}
            onClick={curriedOnOpenOneSchema("builderAmount")}
          >
            Import cost budget
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Flex>
      ),
      subtitle:
        "You don’t have any costs or a budget associated with this project yet.",
    }),
    [curriedOnOpenOneSchema, canManage]
  );

  const lineColumns = useMemo(
    () =>
      getLineColumns({
        hasCategories: categoriesEnabled,
        hasChanges: changeTrackingEnabled,
        hasOwnersAmount: ownersAmountEnabled,
        hasExternalRevisedBudget: clientExternalRevisedBudgetEnabled,
      }),
    [
      categoriesEnabled,
      changeTrackingEnabled,
      clientExternalRevisedBudgetEnabled,
      ownersAmountEnabled,
    ]
  );

  const markupColumns = useMemo(
    () =>
      getMarkupColumns({
        hasChanges: changeTrackingEnabled,
        hasCategories: categoriesEnabled,
        hasExternalBudget: ownersAmountEnabled,
        hasExternalRevisedBudget: clientExternalRevisedBudgetEnabled,
      }),
    [
      categoriesEnabled,
      ownersAmountEnabled,
      changeTrackingEnabled,
      clientExternalRevisedBudgetEnabled,
    ]
  );
  const data = useMemo<
    MultipleTableProps<[BudgetLineItems, MarkupResponse]>["data"]
  >(() => {
    const hasLines = lines.length > 0;
    const hasMarkups = markups.length > 0;

    return [
      {
        data: lines,
        select: lineSelect,
        columns: lineColumns,
        footer: { hide: !hasLines },
        header: { hide: !hasLines },
        loading: isLoadingLines,
        emptyState: lineEmptyState,
      },
      {
        data: markups,
        hide: !hasMarkups,
        columns: markupColumns,
      },
    ];
  }, [
    lineColumns,
    isLoadingLines,
    lineSelect,
    lines,
    markupColumns,
    markups,
    lineEmptyState,
  ]);

  const curriedOnDeleteMarkup = useEvent<CurriedOnDeleteMarkupHandler>(
    (markup) => () => {
      if (!markup) return;

      const handler = async () => {
        const { isSeparateLine, markupType } = markup;

        try {
          if (isSeparateLine && markupType === "percentage") {
            await deleteBudgetLineMarkups({
              customer: job.id,
              markupId: markup.id,
            }).unwrap();
          } else {
            await deleteMarkup({
              markupId: markup.id,
              customer: job.id,
            }).unwrap();
          }

          if (markup.budgetLine && getIsDeletable(markup.budgetLine)) {
            toast.success("Markup item deleted");
          } else {
            toast.success(
              `Markup removed from ${markup.jobCostMethod?.displayName}`
            );
          }
        } catch (e) {
          handleErrors(e);
        }
      };

      dialog.confirmation({
        title: "Remove markup?",
        message:
          markup.budgetLine && getIsDeletable(markup.budgetLine)
            ? `Are you sure you want to remove the markup item ${markup.jobCostMethod?.displayName} from this job?`
            : `${markup.jobCostMethod?.displayName} will revert back to a budget line.`,
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove markup",
          },
        },
      });
    }
  );

  const onDeleteBudgetLine = useEvent<LineItemHandler>((budgetLine) => {
    if (!budgetLine) return;

    const handler = async () => {
      try {
        await deleteBudgetLine({
          customerId: job.id,
          budgetLineId: budgetLine.id,
        }).unwrap();

        toast.success(
          `${budgetLine.jobCostMethod.displayName} removed from the budget`
        );
      } catch (e) {
        handleErrors(e);
      }
    };

    dialog.confirmation({
      title: "Remove line item?",
      action: {
        primary: {
          color: "error",
          onClick: handler,
          children: "Remove line item?",
          "data-testid": "budget-remove-line-confirm-button",
        },
      },
    });
  });

  const onEditBudgetLine = useEvent<LineItemHandler>(async (budgetLine) => {
    const path = job.idListMap[budgetLine.id];
    const currentBudgetLine = dotObject.get(lines, path);
    if (
      currentBudgetLine.builderAmount == budgetLine.builderAmount &&
      currentBudgetLine.builderRevisedAmount ==
        budgetLine.builderRevisedAmount &&
      currentBudgetLine.ownersAmount == budgetLine.ownersAmount
    ) {
      return;
    }

    if (!budgetLine) return;

    try {
      await updateBudgetLine({
        ...budgetLineUpdateRequestPayloadSchema.parse({
          budgetLineId: budgetLine.id,
          customerId: job.id,
          builderAmount: budgetLine.builderAmount,
          ownersAmount: budgetLine.ownersAmount,
        }),
        path,
      });
      toast.success("Changes auto-saved");
    } catch (e) {
      handleErrors(e);
    }
  });

  const updateCategory = useEvent<UpdateCategoryHandler>(
    async ({ budgetLineId, categoryId }) => {
      try {
        await updateBudgetLine({
          category: categoryId || null,
          customerId: String(job.id),
          budgetLineId,
        });

        toast.success("Changes auto-saved");
        analytics.track("budgetLineCategoryUpdate", {
          budgetLineId: budgetLineId,
          categoryId,
          customerId: job.id,
        });
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const onToggleBudgetLockedStatus = useEvent(() => {
    const handler = async (copy = false) => {
      const isBuilderBudgetLocked = !job.is_builder_budget_locked;

      try {
        await updateBudgedLockedStatus({
          customerId: job.id,
          isBuilderBudgetLocked,
        });

        if (copy) copyBuilderBudgetToRevisedBudget(job.idListMap);

        toast.success(
          `Budget ${isBuilderBudgetLocked ? "locked" : "unlocked"}`
        );
      } catch (e) {
        handleErrors(e);
      }
    };

    if (job.is_builder_budget_locked === false) {
      dialog.confirmation({
        title: "Copy Budget to Revised",
        message:
          "Would you also like to update the revised budget column to reflect the changes you made to the original budget?",
        action: {
          primary: {
            onClick: () => handler(true),
            children: "Update revised budget",
          },
          secondary: { children: "Skip", onClick: handler },
        },
      });
    } else {
      handler(job.is_builder_budget_locked === null);
    }
  });

  const curriedOnEditBuildersBudgetChange =
    useEvent<CurriedEditLineItemHandler>((budgetLine) => (value: number) => {
      onEditBudgetLine({
        ...budgetLine,
        builderAmount: value,
      });
      analytics.track("budgetLineUpdate", {
        budgetLineId: budgetLine.id,
        customerId: job.id,
      });
    });

  const curriedOnEditOwnersBudgetChange = useEvent(
    (budgetLine: BudgetLineItems) => (value: number) => {
      onEditBudgetLine({
        ...budgetLine,
        ownersAmount: value,
      });
      analytics.track("budgetLineExternalUpdate", {
        budgetLineId: budgetLine.id,
        customerId: job.id,
      });
    }
  );

  const curriedOnEditWorkflow = useEvent<CurriedLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      markupEditInlineDialog.show();
    }
  );

  const curriedOnSeeTransactions = useEvent<CurriedLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      transactionDialog.show();
    }
  );

  const curriedOnSeeDrawnToDate = useCallback<CurriedOptionalLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      drawnToDateDialogShow();
    },
    [drawnToDateDialogShow]
  );

  const curriedOnSeeChanges = useCallback<CurriedOptionalLineItemHandler>(
    (budgetLine) => () => {
      setCurrentBudgetLine(budgetLine);
      changesDialogShow();
    },
    [changesDialogShow]
  );

  const shouldShowSourceType = useMemo(
    () => !lines.every((line) => line.sourceType === lines[0].sourceType),
    [lines]
  );

  const onEditFixedAmountMarkup = useCallback<OnEditFixedAmountMarkupHandler>(
    (markup) => {
      setCurrentMarkup(markup);
      markupEditFixedAmountDialog.show();
    },
    [markupEditFixedAmountDialog]
  );

  const { lineItemsTotals, markupLineTotals, grandLineItemTotals } =
    useTableTotals({ budgetLines: lines, markupLines: markups });

  const markupsFooter = useMemo(() => {
    const transactions = categoriesEnabled
      ? [
          <AddMarkupLineButton key="markup-add-button" />,
          <Flex
            align="center"
            width="full"
            key="markup-total"
            justify="flex-end"
          >
            <Text weight="bold">Markup total</Text>
          </Flex>,
        ]
      : [
          <Flex
            key="markup-total"
            gap="md"
            width="full"
            align="center"
            justify="space-between"
          >
            <AddMarkupLineButton />
            <Text weight="bold">Markup total</Text>
          </Flex>,
        ];

    const costs = [
      <Flex key="markup-budget" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(markupLineTotals.builderAmount, CURRENCY_FORMAT)}
        </Text>
      </Flex>,
    ];

    if (changeTrackingEnabled) {
      costs.push(
        <Flex
          key="markup-changes-total"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(markupLineTotals.changeAmount, CURRENCY_FORMAT)}
          </Text>
        </Flex>,
        <Flex
          key="markup-revised-budget"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(
              markupLineTotals.builderRevisedAmount,
              CURRENCY_FORMAT
            )}
          </Text>
        </Flex>
      );
    }

    costs.push(
      <Flex key="markup-spent" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(markupLineTotals.spent, CURRENCY_FORMAT)}
        </Text>
      </Flex>
    );

    if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
      costs.push(
        <Flex
          key="markup-unpaid-bills"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(markupLineTotals.unpaidBills, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      );
    }

    costs.push(
      <Flex
        key="markup-budget-remaining"
        width="full"
        align="center"
        justify="flex-end"
      >
        <Text weight="bold">
          {formatCurrency(
            markupLineTotals.builderRemainingAmount,
            CURRENCY_FORMAT
          )}
        </Text>
      </Flex>
    );

    const revenues = [];

    if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
      revenues.push(<div />);
    }

    if (ownersAmountEnabled) {
      revenues.push(
        <Flex
          key="markup-owners-amount"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(markupLineTotals.ownersAmount, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      );

      if (changeTrackingEnabled && clientExternalRevisedBudgetEnabled) {
        revenues.push(
          <Flex
            key="markup-revenues-changes-total"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">
              {formatCurrency(
                markupLineTotals.externalChangeAmount,
                CURRENCY_FORMAT
              )}
            </Text>
          </Flex>,
          <Flex
            key="markup-revenues-revised-budget"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">
              {formatCurrency(
                markupLineTotals.ownersRevisedAmount,
                CURRENCY_FORMAT
              )}
            </Text>
          </Flex>
        );
      }
    }

    revenues.push(
      <Flex key="markup-drawn" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(markupLineTotals.invoicedAmount, CURRENCY_FORMAT)}
        </Text>
      </Flex>,
      <Flex
        key="markup-drawn-remaining"
        width="full"
        align="center"
        justify="flex-end"
      >
        <Text weight="bold">
          {formatCurrency(
            markupLineTotals.invoicedRemainingAmount,
            CURRENCY_FORMAT
          )}
        </Text>
      </Flex>
    );

    return [...transactions, ...costs, ...revenues];
  }, [
    categoriesEnabled,
    markupLineTotals.builderAmount,
    markupLineTotals.spent,
    markupLineTotals.builderRemainingAmount,
    markupLineTotals.invoicedAmount,
    markupLineTotals.invoicedRemainingAmount,
    markupLineTotals.changeAmount,
    markupLineTotals.builderRevisedAmount,
    markupLineTotals.unpaidBills,
    markupLineTotals.ownersAmount,
    markupLineTotals.externalChangeAmount,
    markupLineTotals.ownersRevisedAmount,
    changeTrackingEnabled,
    ownersAmountEnabled,
    clientExternalRevisedBudgetEnabled,
  ]);

  const totalsFooter = useMemo(() => {
    const transactions = categoriesEnabled
      ? [
          <div key="grand-total-empty" />,
          <Flex
            key="grand-total"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">Grand total</Text>
          </Flex>,
        ]
      : [
          <Flex
            key="grand-total"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">Grand total</Text>
          </Flex>,
        ];

    const costs = [
      <Flex key="grand-budget" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(grandLineItemTotals.builderAmount, CURRENCY_FORMAT)}
        </Text>
      </Flex>,
    ];

    if (changeTrackingEnabled) {
      costs.push(
        <Flex
          key="grand-changes"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(grandLineItemTotals.changeAmount, CURRENCY_FORMAT)}
          </Text>
        </Flex>,
        <Flex
          key="grand-revised-budget"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(
              grandLineItemTotals.builderRevisedAmount,
              CURRENCY_FORMAT
            )}
          </Text>
        </Flex>
      );
    }

    costs.push(
      <Flex key="grand-actual" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(grandLineItemTotals.spent, CURRENCY_FORMAT)}
        </Text>
      </Flex>
    );

    if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
      costs.push(
        <Flex
          key="grand-unpaid-bills"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(grandLineItemTotals.unpaidBills, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      );
    }

    costs.push(
      <Flex
        key="grand-budget-remaining"
        width="full"
        align="center"
        justify="flex-end"
      >
        <Text weight="bold">
          {formatCurrency(
            grandLineItemTotals.builderRemainingAmount,
            CURRENCY_FORMAT
          )}
        </Text>
      </Flex>
    );

    const revenues = [];

    if (window.BUDGET_MARKUP_COLUMN_ENABLED) revenues.push(<div />);

    if (ownersAmountEnabled) {
      revenues.push(
        <Flex
          key="grand-owners-budget"
          width="full"
          align="center"
          justify="flex-end"
        >
          <Text weight="bold">
            {formatCurrency(grandLineItemTotals.ownersAmount, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      );

      if (changeTrackingEnabled && clientExternalRevisedBudgetEnabled) {
        revenues.push(
          <Flex
            key="grand-changes"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">
              {formatCurrency(
                grandLineItemTotals.externalChangeAmount ?? 0,
                CURRENCY_FORMAT
              )}
            </Text>
          </Flex>,
          <Flex
            key="grand-revised-budget"
            width="full"
            align="center"
            justify="flex-end"
          >
            <Text weight="bold">
              {formatCurrency(
                grandLineItemTotals.ownersRevisedAmount,
                CURRENCY_FORMAT
              )}
            </Text>
          </Flex>
        );
      }
    }

    revenues.push(
      <Flex key="grand-drawn" width="full" align="center" justify="flex-end">
        <Text weight="bold">
          {formatCurrency(grandLineItemTotals.invoicedAmount, CURRENCY_FORMAT)}
        </Text>
      </Flex>,
      <Flex
        key="grand-drawn-remaining"
        width="full"
        align="center"
        justify="flex-end"
      >
        <Text weight="bold">
          {formatCurrency(
            grandLineItemTotals.invoicedRemainingAmount,
            CURRENCY_FORMAT
          )}
        </Text>
      </Flex>
    );

    return [...transactions, ...costs, ...revenues];
  }, [
    categoriesEnabled,
    changeTrackingEnabled,
    clientExternalRevisedBudgetEnabled,
    grandLineItemTotals.builderAmount,
    grandLineItemTotals.builderRemainingAmount,
    grandLineItemTotals.builderRevisedAmount,
    grandLineItemTotals.changeAmount,
    grandLineItemTotals.externalChangeAmount,
    grandLineItemTotals.invoicedAmount,
    grandLineItemTotals.invoicedRemainingAmount,
    grandLineItemTotals.ownersAmount,
    grandLineItemTotals.ownersRevisedAmount,
    grandLineItemTotals.spent,
    grandLineItemTotals.unpaidBills,
    ownersAmountEnabled,
  ]);

  const footer = useMemo<MultipleTableFooter>(
    () => ({
      data: [markupsFooter, totalsFooter],
      offset: -64,
    }),
    [markupsFooter, totalsFooter]
  );

  /**
   * The process of opening an specific change in changes dialog starts here
   * but you can check the final logic inside the `ChangesDialog` component.
   */
  useEffect(() => {
    if (locationParam === "changes" && changeParam && !isLoadingLines) {
      changesDialogShow();
    }
  }, [changeParam, isLoadingLines, locationParam, changesDialogShow]);

  return (
    <>
      {(oneSchemaIsLoading || budgetIsLoading) && <Loader position="fixed" />}
      <BudgetsTableContext.Provider
        value={{
          ...lineItemsTotals,
          totalOwnersAmount: grandLineItemTotals.ownersAmount,
          totalExternalChangeAmount: grandLineItemTotals.externalChangeAmount,
          totalOwnersRevisedAmount: grandLineItemTotals.ownersRevisedAmount,
          totalInvoicedAmount: grandLineItemTotals.invoicedAmount,
          shouldShowSourceType,
          isOwnersBudgetLocked: job.is_owner_budget_locked,
          isBuilderBudgetLocked: job.is_builder_budget_locked,
          totalInvoicedRemainingAmount:
            grandLineItemTotals.invoicedRemainingAmount,
          updateCategory,
          onDeleteBudgetLine,
          onEditBudgetLine,
          curriedOnDeleteMarkup,
          curriedOnEditWorkflow,
          onEditFixedAmountMarkup,
          curriedOnSeeTransactions,
          curriedOnSeeDrawnToDate,
          curriedOnSeeChanges,
          onToggleBudgetLockedStatus,
          curriedOnEditOwnersBudgetChange,
          curriedOnEditBuildersBudgetChange,
          curriedOnOpenOneSchema,
        }}
      >
        <MultipleTable
          size="sm"
          data={data}
          widths={widths}
          header={hasLines ? header : undefined}
          footer={hasLines ? footer : undefined}
          data-testid="budget-table"
        />
      </BudgetsTableContext.Provider>
      {changesDialog.isRendered && (
        <ChangesDialog dialog={changesDialog} budgetLine={currentBudgetLine} />
      )}
      {transactionDialog.isRendered && currentBudgetLine && (
        <TransactionDialog
          dialog={transactionDialog}
          budgetLine={currentBudgetLine}
        />
      )}
      {drawnToDateDialog.isRendered && (
        <DrawnToDateDialog
          dialog={drawnToDateDialog}
          budgetLine={currentBudgetLine}
        />
      )}
      {markupEditInlineDialog.isRendered && currentBudgetLine && (
        <EditInlineMarkupDialog
          dialog={markupEditInlineDialog}
          budgetLineItem={currentBudgetLine}
        />
      )}
      {markupEditFixedAmountDialog.isRendered && (
        <EditFixedMarkupDialog
          dialog={markupEditFixedAmountDialog}
          markup={currentMarkup}
        />
      )}
      {window.ONESCHEMA_CLIENT_ID &&
        window.ONESCHEMA_TEMPLATE_ID &&
        window.ONESCHEMA_JWT && (
          <OneSchemaImporter
            style={{
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              zIndex: 99999999,
              position: "fixed",
            }}
            isOpen={oneSchemaIsOpen}
            devMode={import.meta.env.DEV}
            onError={onOneSchemaError}
            userJwt={window.ONESCHEMA_JWT}
            clientId={window.ONESCHEMA_CLIENT_ID}
            onSuccess={onOneSchemaSuccess}
            onLaunched={onOneSchemaLaunched}
            templateKey={window.ONESCHEMA_TEMPLATE_ID}
            importConfig={{ type: "local" }}
            onRequestClose={onRequestClose}
            templateOverrides={templateOverrides}
          />
        )}
    </>
  );
});

BudgetsTable.displayName = "BudgetsTable";
