import React from "react";
import { type TableColumn, Text, Tooltip } from "@adaptive/design-system";
import type { BudgetLineItems } from "@api/jobs";

import {
  CostsActualColumn,
  CostsActualFooter,
  CostsBudgetColumn,
  CostsBudgetFooter,
  CostsBudgetHeader,
  CostsBudgetRemainingHeader,
  CostsChangesColumn,
  CostsChangesFooter,
  CostsChangesHeader,
  CostsRemainingColumn,
  CostsRemainingFooter,
  CostsRevisedBudgetColumn,
  CostsRevisedBudgetFooter,
  CostsRevisedBudgetHeader,
  CostsUnpaidColumn,
  CostsUnpaidFooter,
  EmptyColumn,
  RevenuesChangesColumn,
  RevenuesChangesFooter,
  RevenuesChangesHeader,
  RevenuesDrawnColumn,
  RevenuesDrawnFooter,
  RevenuesDrawnHeader,
  RevenuesDrawnRemainingColumn,
  RevenuesDrawnRemainingFooter,
  RevenuesDrawRemainingHeader,
  RevenuesMarkupColumn,
  RevenuesOwnersBudgetColumn,
  RevenuesOwnersBudgetFooter,
  RevenuesOwnersBudgetHeader,
  RevenuesRevisedBudgetColumn,
  RevenuesRevisedBudgetFooter,
  RevenuesRevisedBudgetHeader,
  TransactionsCategoryColumn,
  TransactionsCategoryFooter,
  TransactionsLineColumn,
  TransactionsLineFooter,
} from "./lines-components";

type LinesColumn = TableColumn<BudgetLineItems>;

export const getIsDeletable = (row: BudgetLineItems) => {
  return (
    row.jobCostMethod.url !== row.jobCostMethod.parent &&
    !row.spent &&
    !row.hasInvoices &&
    !row.hasChanges
  );
};

/**
 * Columns definition
 */
type GetLineColumnsProps = {
  hasCategories: boolean;
  hasChanges: boolean;
  hasOwnersAmount: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getLineColumns = ({
  hasChanges,
  hasCategories,
  hasOwnersAmount,
  hasExternalRevisedBudget,
}: GetLineColumnsProps) => {
  const info: LinesColumn = {
    id: "info",
    columns: [
      {
        id: "name",
        name: <Text weight="bold">Line items</Text>,
        render: (row) => <TransactionsLineColumn {...row} />,
        footer: <TransactionsLineFooter />,
      },
    ],
  };

  if (hasCategories) {
    info.columns.push({
      id: "category",
      name: <Text weight="bold">Categories</Text>,
      render: (row) => <TransactionsCategoryColumn {...row} />,
      footer: <TransactionsCategoryFooter />,
    });
  }

  const costs: LinesColumn = {
    id: "costs",
    name: "Costs",
    columns: [
      {
        id: "budget",
        name: <CostsBudgetHeader />,
        render: (row) => <CostsBudgetColumn {...row} />,
        footer: <CostsBudgetFooter />,
      },
    ],
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        name: <CostsChangesHeader />,
        render: (row) => <CostsChangesColumn {...row} />,
        footer: <CostsChangesFooter />,
      },
      {
        id: "revisedBudget",
        name: <CostsRevisedBudgetHeader />,
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
        footer: <CostsRevisedBudgetFooter />,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    name: (
      <Tooltip message="Total cost of approved bills & receipts">
        <Text weight="bold">Actual costs to date</Text>
      </Tooltip>
    ),
    render: (row) => <CostsActualColumn {...row} />,
    footer: <CostsActualFooter />,
  });

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costs.columns.push({
      id: "unpaid",
      name: (
        <Tooltip message="Total cost of unpaid bills">
          <Text weight="bold">Unpaid bills</Text>
        </Tooltip>
      ),
      render: (row) => <CostsUnpaidColumn {...row} />,
      footer: <CostsUnpaidFooter />,
    });
  }

  costs.columns.push({
    id: "costsRemaining",
    name: <CostsBudgetRemainingHeader />,
    render: (row) => <CostsRemainingColumn {...row} />,
    footer: <CostsRemainingFooter />,
  });

  const revenues: LinesColumn = {
    id: "revenues",
    name: "Revenue",
    columns: [],
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      name: "Markup",
      render: (row) => <RevenuesMarkupColumn {...row} />,
      footer: <EmptyColumn />,
    });
  }

  if (hasOwnersAmount) {
    revenues.columns.push({
      id: "ownersBudget",
      name: <RevenuesOwnersBudgetHeader />,
      render: (row) => <RevenuesOwnersBudgetColumn {...row} />,
      footer: <RevenuesOwnersBudgetFooter />,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "changes",
          name: <RevenuesChangesHeader />,
          render: (row) => <RevenuesChangesColumn {...row} />,
          footer: <RevenuesChangesFooter />,
        },
        {
          id: "revisedBudget",
          name: <RevenuesRevisedBudgetHeader />,
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
          footer: <RevenuesRevisedBudgetFooter />,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      name: (
        <Tooltip message="Total amount on draft & synced draws">
          <RevenuesDrawnHeader />
        </Tooltip>
      ),
      render: (row) => <RevenuesDrawnColumn {...row} />,
      footer: <RevenuesDrawnFooter />,
    },
    {
      id: "remaining",
      name: <RevenuesDrawRemainingHeader />,
      render: (row) => <RevenuesDrawnRemainingColumn {...row} />,
      footer: <RevenuesDrawnRemainingFooter />,
    }
  );

  return [info, costs, revenues];
};
