import React from "react";
import { Tag } from "@adaptive/design-system";
import {
  dotObject,
  formatCurrency,
  formatDate,
  parseCurrency,
} from "@adaptive/design-system/utils";
import type { BillLienWaiver } from "@lien-waiver/types";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "@store/types";
import { selectRealmId } from "@store/user/selectors-memoized";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import {
  type KeysToCamelCase,
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import { stringCompare } from "@utils/string-compare";
import { sum } from "@utils/sum";
import {
  humanReadableBillReviewStatus,
  isObjectEmpty,
  reviewStatusColor,
} from "@utils/usefulFunctions";

import {
  BILL_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
  TAB_STATUS,
  TAB_STATUS_TO_BILLS_CONST,
} from "./constants";
import type {
  Bill,
  BillListItem,
  BillListItems,
  LienWaiverItem,
  QueryFilters,
  Status,
  StatusKey,
} from "./types";

const enterBillManuallySelector = (state: RootState) =>
  state.bill.enterBillManually;

const rawBillSelector = (state: RootState) =>
  state.bill.bill as Omit<
    RootState["bill"]["bill"],
    "review_status" | "initial_review_status"
  > & {
    id?: string;
    review_status: (typeof BILL_STATUS)[keyof typeof BILL_STATUS];
    initial_review_status: (typeof BILL_STATUS)[keyof typeof BILL_STATUS];
  };

export const billListSelector = (state: RootState) => state.billList;

export const billListFilterSelector = createSelector(
  billListSelector,
  selectRealmId,
  ({ filter: { query, values }, ...billListItems }, realmId) => {
    const enhancedFilter = { query, values };

    if (realmId) {
      enhancedFilter.query = [
        { dataIndex: "realm", value: realmId },
        ...enhancedFilter.query.filter((item) => item.dataIndex !== "realm"),
      ];
    }

    return {
      filter: enhancedFilter,
      billList: billListItems as BillListItems,
    };
  }
);

export const billSelector = createSelector(
  rawBillSelector,
  enterBillManuallySelector,
  (bill, enterBillManually) => {
    /**
     * @todo for some reason realm data on bill creation is an object
     * but on edit is a string of url. we didn't changed it now since it
     * could break some places, but we need to check it soon to make it
     * less fragile.
     */
    const realm = !bill.id
      ? null
      : dotObject.get(bill, "realm.url")
        ? bill.realm
        : bill.realm
          ? { url: bill.realm }
          : null;

    return { ...bill, realm, enterBillManually };
  }
);

const rootBillSelector = (state: RootState) => state.bill;

export const statusBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.billStatus
);

export const formEditedBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.billFormEdited
);

export const showCreateNewDialogBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.showCreateNewDialog
);

export const predictionBillSelector = createSelector(
  rootBillSelector,
  (bill) => bill.prediction
);

export const duplicatesBillSelector = createSelector(billSelector, (bill) =>
  (bill.duplicate || []).reduce(
    (acc, item: Record<string, unknown>) =>
      item.id == bill.id
        ? acc
        : [...acc, transformKeysToCamelCase(transformKeysToSnakeCase(item))],
    [] as Record<string, unknown>[]
  )
);

export const isInPaymentStatusBill = (reviewStatus: string) =>
  ["PAID", "ACH_PROCESSING", "PARTIALLY_PAID"].includes(reviewStatus);

/**
 * This logic should follows the same logic as the one in the backend, you can check the backend
 * logic on `get_automatically_archived` method in @see /backend/adaptive_quickbooks/enums.py
 */
type IsArchivedProps =
  | { archived?: boolean; review_status: string }
  | { archived?: boolean; reviewStatus: string };

/**
 * This function returns `true` when bill is "real archived"
 */
export const isArchivedByUserBill = (bill: IsArchivedProps) => {
  const reviewStatus =
    "review_status" in bill ? bill.review_status : bill.reviewStatus;

  return (
    bill.archived &&
    !["PAID", "ACH_PROCESSING", "VOID", "PAYMENT_FAILED"].includes(reviewStatus)
  );
};

export const camelCaseBillSelector = createSelector(billSelector, (bill) => {
  const isArchivedByUser = isArchivedByUserBill(bill);

  const billLienWaiverTemplate =
    bill.default_lien_waiver_template ??
    (bill.default_lien_waiver_status === "not_required"
      ? bill.default_lien_waiver_status
      : null);

  return {
    id: bill.id,
    url: bill.url,
    realm: bill.realm,
    balance: parseCurrency(bill.balance || "0") ?? 0,
    docNumber: bill.doc_number,
    realmUrl: bill.realm?.url,
    linesCount: bill.lines_count,
    isArchived: bill.archived,
    isArchivedByUser,
    amountToPay: bill.amount_to_pay,
    tooManyLines: bill.lines_count > window.MAX_LINES_PAGE_SIZE,
    reviewStatus: bill.review_status,
    isVendorCredit: bill.is_vendor_credit,
    taxValue: bill.tax?.value ?? 0,
    taxIsSet: bill.tax?.isSet ?? false,
    initialReviewStatus: bill.initial_review_status,
    shouldShowPurchaseOrders:
      bill.vendor?.url && !bill.is_vendor_credit && !isArchivedByUser,
    vendorId: parseRefinementIdFromUrl(bill.vendor?.url ?? ""),
    isCreator: bill.is_creator,
    canApprove: bill.can_approve,
    canBeEdited: bill.can_be_edited,
    hasAttachables: bill.attachables?.length > 0,
    alreadyEvaluated: bill.is_approval_evaluated,
    isApproved:
      bill.is_approved && bill.review_status === BILL_STATUS.FOR_PAYMENT,
    date: bill.date as string | null,
    dueDate: bill.due_date as string | null,
    createdAt: bill.created_at as string | null,
    totalAmount: parseCurrency(bill.total_amount || "0") ?? 0,
    enterBillManually: bill.enterBillManually,
    publishedToQuickbooks: bill.published_to_quickbooks,
    linkedInvoices: bill.linked_invoices.map(transformKeysToCamelCase),
    billLienWaiverTemplate,
    lienWaivers: bill.lien_waivers.map(
      transformKeysToCamelCase
    ) as BillLienWaiver[],
  };
});

export const billTaxValueSelector = createSelector(
  camelCaseBillSelector,
  (bill) => (bill.taxIsSet ? bill.taxValue : 0)
);

export const billAmountToPaySelector = createSelector(
  camelCaseBillSelector,
  (bill) => parseCurrency(bill.amountToPay || "") || 0
);

export const billSubTotalSelector = createSelector(
  camelCaseBillSelector,
  (bill) => sum(bill.totalAmount, -(bill.taxIsSet ? bill.taxValue : 0))
);

export const billPublishedToQuickbooksSelector = createSelector(
  camelCaseBillSelector,
  (bill) => bill.publishedToQuickbooks
);

export const errorsBillSelector = createSelector(
  billSelector,
  (bill) => bill.errors
);

export const relatedErrorsBillSelector = createSelector(
  billSelector,
  (bill) => bill.related_errors
);

export const paymentsBillSelector = createSelector(
  billSelector,
  (bill) => bill?.bill_payments
);

export const vendorBillSelector = createSelector(billSelector, (bill) => ({
  ...bill.vendor,
  id: parseRefinementIdFromUrl(bill.vendor?.url ?? ""),
  displayName: bill.vendor?.display_name,
}));

type Line = {
  id?: string;
  item?: {
    url?: string;
    display_name?: string;
  };
  amount?: number;
  deleted?: boolean;
  account?: {
    url?: string;
    display_name?: string;
  };
  customer?: {
    id?: string;
    url?: string;
    display_name?: string;
  };
  is_spent: boolean;
  description?: string;
  billable_status?: string;
  linked_transaction?: BillLineLinkedTransaction;
  remaining_budget_after_this_bill: number | null;
  remaining_budget_after_this_bill_blocked?: boolean;
  parent?: {
    is_in_quickbooks: boolean;
  };
};

export const linesBillSelector = createSelector(
  billSelector,
  (bill) => bill.lines as Line[]
);

export const camelCaseLinesBillSelector = createSelector(
  linesBillSelector,
  (lines) => lines.map(transformKeysToCamelCase)
);

export const camelCaseLinkedTransactionsBillSelector = createSelector(
  camelCaseLinesBillSelector,
  (lines) =>
    lines.reduce(
      (acc, line) => {
        if (!line.linkedTransaction) return acc;

        const previousLinkedTransaction = acc.find(
          (linkedTransaction) =>
            linkedTransaction.objectId === line.linkedTransaction?.objectId
        );

        if (previousLinkedTransaction) {
          return acc.map((linkedTransaction) =>
            linkedTransaction.objectId === line.linkedTransaction?.objectId
              ? {
                  ...linkedTransaction,
                  lines: [
                    ...linkedTransaction.lines,
                    ...(line.linkedTransaction.id
                      ? [line.linkedTransaction.id]
                      : []),
                  ],
                }
              : linkedTransaction
          );
        }

        return [
          ...acc,
          {
            ...line.linkedTransaction,
            lines: line.linkedTransaction.id ? [line.linkedTransaction.id] : [],
          },
        ];
      },
      [] as (KeysToCamelCase<BillLineLinkedTransaction> & { lines: number[] })[]
    )
);

export const camelCaseQuickBooksLinkedTransactionsBillSelector = createSelector(
  camelCaseLinkedTransactionsBillSelector,
  (linkedTransactions) => [
    ...linkedTransactions
      .reduce(
        (map, transaction) =>
          transaction.parent.type === "qb"
            ? map.set(transaction.objectId, transaction)
            : map,
        new Map<
          number,
          KeysToCamelCase<BillLineLinkedTransaction> & { lines: number[] }
        >()
      )
      .values(),
  ]
);

export const billHasLinkedPurchaseOrderSelector = createSelector(
  camelCaseLinesBillSelector,
  (lines) => lines.some((line) => line.linkedTransaction)
);

export const commentsBillSelector = createSelector(
  billSelector,
  (bill) => bill.comments
);

export const defaultBankAccountBillSelector = createSelector(
  billSelector,
  (bill) =>
    bill?.customers
      ?.map((customer) => (customer as any)?.bank_account)
      .concat([bill?.default_account_balance?.url])
      .filter(Boolean)
);

export const approvalsBillSelector = createSelector(billSelector, (bill) =>
  bill.approvals.map(transformKeysToCamelCase)
);

export const workflowsBillSelector = createSelector(billSelector, (bill) =>
  bill.approval_workflows.map(transformKeysToCamelCase)
);

export const billIdSelector = createSelector(billSelector, (bill) => bill.id);

export const isLoadingBillSelector = createSelector(
  statusBillSelector,
  (status) => status === "loading"
);

const BILL_INFO_FIELDS_TO_DIRTY_CHECK = [
  "date",
  "due_date",
  "tax.value",
  "doc_number",
  "is_vendor_credit",
];

const BILL_INFO_OBJ_FIELDS_TO_DIRTY_CHECK = ["vendor"];

const BILL_LINES_FIELDS_TO_DIRTY_CHECK = [
  "amount",
  "description",
  "billable_status",
];

const BILL_LINES_OBJ_FIELDS_TO_DIRTY_CHECK = ["item", "account", "customer"];

const BILL_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK = [
  "amount",
  "description",
  "billable_status",
];

const BILL_INVOICED_LINES_OBJ_FIELDS_TO_DIRTY_CHECK = [
  "item",
  "account",
  "customer",
];

const getUrl = (obj: unknown): obj is string =>
  typeof obj === "object" ? dotObject.get(obj as object, "url") : obj;

const selectStaticBill = (state: RootState) => state.bill.staticBill;

export const staticBillTaxValueSelector = createSelector(
  selectStaticBill,
  (bill) => (bill.tax.isSet ? bill.tax.value || 0 : 0)
);

export const staticBillSubTotalSelector = createSelector(
  selectStaticBill,
  (bill) =>
    sum(bill.total_amount || 0, -(bill.tax.isSet ? bill.tax.value || 0 : 0))
);

const selectDynamicBill = (state: RootState) => state.bill.bill;

export const currentStaticReviewStatus = createSelector(
  selectStaticBill,
  (bill) => bill.review_status
);

export const staticLinesBillSelector = createSelector(
  selectStaticBill,
  (bill) => bill.lines as Line[]
);

export const isDirtyLinkedInvoiceLines = createSelector(
  selectStaticBill,
  selectDynamicBill,
  (staticBill, dynamicBill) => {
    if (!(staticBill as any)?.id) return false;

    const staticLines = staticBill.lines.filter(
      (line) =>
        !(line as any).deleted && !!(line as any).linked_to_draft_invoice
    );
    const dynamicLines = dynamicBill.lines.filter(
      (line) =>
        !(line as any).deleted && !!(line as any).linked_to_draft_invoice
    );
    const vendorChanged =
      getUrl(dynamicBill.vendor) !== getUrl(staticBill.vendor) &&
      staticLines.length > 0;

    return (
      vendorChanged ||
      staticLines.some((line, index) => {
        const dynamicLine = dynamicLines[index];

        const isInfoDirty = BILL_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK.some(
          (field) => {
            const previousValue = dotObject.get(line, field);
            const nextValue = dotObject.get(dynamicLine, field);
            const hasChanged = nextValue != previousValue;

            if (
              hasChanged &&
              field === "billable_status" &&
              nextValue === "NotBillable"
            ) {
              return true;
            }

            return hasChanged;
          }
        );

        if (isInfoDirty) return true;

        return BILL_INVOICED_LINES_OBJ_FIELDS_TO_DIRTY_CHECK.some(
          (field) =>
            getUrl(dotObject.get(dynamicLine, field)) !=
            getUrl(dotObject.get(line, field))
        );
      })
    );
  }
);

export const isDirtyBillSelector = createSelector(
  selectStaticBill,
  selectDynamicBill,
  (staticBill, dynamicBill) => {
    if (!(staticBill as any)?.id) return false;

    const isInfoDirty = BILL_INFO_FIELDS_TO_DIRTY_CHECK.some(
      (field) =>
        dotObject.get(dynamicBill, field) != dotObject.get(staticBill, field)
    );

    if (isInfoDirty) return true;

    const isInfoObjDirty = BILL_INFO_OBJ_FIELDS_TO_DIRTY_CHECK.some(
      (field) =>
        getUrl(dotObject.get(dynamicBill, field)) !=
        getUrl(dotObject.get(staticBill, field))
    );

    if (isInfoObjDirty) return true;

    const staticLines = staticBill.lines.filter(
      (line) => !(line as any).deleted
    );
    const dynamicLines = dynamicBill.lines.filter(
      (line) => !(line as any).deleted
    );

    if (staticLines.length !== dynamicLines.length) return true;

    return staticLines.some((line, index) => {
      const dynamicLine = dynamicLines[index];

      const isInfoDirty = BILL_LINES_FIELDS_TO_DIRTY_CHECK.some(
        (field) =>
          dotObject.get(dynamicLine, field) != dotObject.get(line, field)
      );

      if (isInfoDirty) return true;

      return BILL_LINES_OBJ_FIELDS_TO_DIRTY_CHECK.some(
        (field) =>
          getUrl(dotObject.get(dynamicLine, field)) !=
          getUrl(dotObject.get(line, field))
      );
    });
  }
);

type IsLoadingBillType = Pick<Bill, "file_sync_status">;

export const isLoadingBill = (
  bill: IsLoadingBillType | KeysToCamelCase<IsLoadingBillType>
) => {
  if (isObjectEmpty(bill)) return false;

  const fileSyncStatus = dotObject.get(
    bill,
    "file_sync_status",
    dotObject.get(bill, "fileSyncStatus")
  );

  return ["deferred", "requested", "in-progress"].includes(fileSyncStatus);
};

export const isErrorBill = (bill: Bill) => !!bill.errors_not_ignored_exist;

type RenderStatusBill = Pick<
  Bill,
  | "archived"
  | "is_rejected"
  | "review_status"
  | "file_sync_status"
  | "errors_ignored_exist"
  | "errors_not_ignored_exist"
>;

export const renderStatus = (
  bill: RenderStatusBill | KeysToCamelCase<RenderStatusBill>,
  testId?: string
) => {
  const reviewStatus = dotObject.get(
    bill,
    "review_status",
    dotObject.get(bill, "reviewStatus")
  );

  let color = reviewStatusColor(reviewStatus);
  let content = humanReadableBillReviewStatus(reviewStatus);

  const isRejected = dotObject.get(
    bill,
    "is_rejected",
    dotObject.get(bill, "isRejected")
  );

  const isArchivedByUser = isArchivedByUserBill(bill);

  const isPartiallyPaid = reviewStatus === BILL_STATUS.PARTIALLY_PAID;

  const errorsIgnoredExist = dotObject.get(
    bill,
    "errors_ignored_exist",
    dotObject.get(bill, "errorsIgnoredExist")
  );

  const errorsNotIgnoredExist = dotObject.get(
    bill,
    "errors_not_ignored_exist",
    dotObject.get(bill, "errorsNotIgnoredExist")
  );

  if (isRejected) {
    color = "error";
    content = "Rejected";
  } else if (isArchivedByUser) {
    color = "neutral";
    content = `Archived ${content.toLowerCase().replace("ach", "ACH")}`;
  } else if (isPartiallyPaid) {
    color = "warning";
    content = "Partially paid";
  } else if (errorsNotIgnoredExist) {
    color = "error";
    content = "Sync errors";
  } else if (errorsIgnoredExist) {
    color = "neutral";
    content = "Ignored sync errors";
  }

  return (
    !isLoadingBill(bill) && (
      <Tag color={color} data-testid={testId}>
        {content}
      </Tag>
    )
  );
};

export const getBillsByStatus = (billList: BillListItems, status: Status) => {
  if (status === "all") {
    return billList.allBills;
  } else if (status === "draft") {
    return billList.draftBills;
  } else if (status === "approval") {
    return billList.approvalBills;
  } else if (status === "for-payment") {
    return billList.forPaymentBills;
  }

  return {
    bills: [],
    loading: "loaded",
    total: 0,
    filter: { values: {}, query: [] },
  } as BillListItem;
};

export const getBillConfigByStatus = (status: Status) => {
  return TAB_STATUS_TO_BILLS_CONST[status];
};

export const isValidStatus = (status: string | null): status is Status =>
  !!(status && Object.values(TAB_STATUS).includes(status as Status));

export const getOrderingByStatus = (status: Status | StatusKey) => {
  return ["all", "ALL", "for-payment", "FOR_PAYMENT"].includes(status)
    ? "-date"
    : "-true_created_at";
};

export const sortQuery = (query: QueryFilters) =>
  query.slice().sort((a, b) => stringCompare(a.dataIndex, b.dataIndex));

export const getSourceEmail = (bill: {
  email_body_attachable?: { document?: string };
}) => {
  if (!bill.email_body_attachable?.document) {
    return null;
  }

  return (
    <Tag
      as="button"
      type="button"
      onClick={() =>
        window.open(bill.email_body_attachable?.document, "_blank")
      }
      color="info"
    >
      View source email
    </Tag>
  );
};

export const getReceivedDate = (bill: {
  id?: string;
  created_at?: string | null;
}) => {
  if (!(bill.id && bill.created_at)) {
    return null;
  }

  const formattedDate = formatDate(new Date(bill.created_at));

  return (
    <Tag>
      Received on <time>{formattedDate}</time>
    </Tag>
  );
};

export const getTransactionType = (url: string) =>
  url.includes("bill") ? "Bill" : "Receipt";

export const getTransactionRoute = (url: string) =>
  `/${
    getTransactionType(url) === "Bill" ? "bills" : "expenses"
  }/${parseRefinementIdFromUrl(url)}`;

type BillLineLinkedTransaction = {
  id: number;
  url: string;
  spent?: string | number;
  amount: string | number;
  parent: {
    id: number;
    url: string;
    type: string;
    doc_number: string;
  };
  type: string;
  balance: string | number;
  object_id: number;
};

type GetLineTotalsLinkedTransaction = Pick<
  BillLineLinkedTransaction,
  "spent" | "amount" | "balance"
>;

type BillLine = {
  amount?: string | number;
  isSpent?: boolean;
  is_spent?: boolean;
  linkedTransaction?: KeysToCamelCase<GetLineTotalsLinkedTransaction>;
  linked_transaction?: GetLineTotalsLinkedTransaction;
};

export const getLineTotals = ({
  tax,
  line,
  subTotal,
  staticTax = 0,
  staticSubTotal = 0,
  staticLineAmount = 0,
}: {
  tax: number;
  line: BillLine;
  subTotal: number;
  staticTax?: number;
  staticSubTotal?: number;
  staticLineAmount?: number;
}) => {
  const isSpent = line.is_spent || line.isSpent;

  const linkedTransaction = line.linked_transaction || line.linkedTransaction;

  const purchaseOrderAmount =
    parseCurrency(String(linkedTransaction?.amount) || "0") || 0;

  let thisLineAmount = parseCurrency(String(line.amount) || "0") || 0;

  let prevSpent = parseCurrency(String(linkedTransaction?.spent) || "0") || 0;

  if (tax) {
    thisLineAmount = sum(thisLineAmount, (thisLineAmount * tax) / subTotal);
  }

  if (isSpent) {
    let staticThisLineAmount =
      parseCurrency(String(staticLineAmount) || "0") || 0;

    if (staticTax) {
      staticThisLineAmount = sum(
        staticThisLineAmount,
        (staticThisLineAmount * staticTax) / staticSubTotal
      );
    }

    prevSpent = sum(prevSpent, -staticThisLineAmount);
  }

  const purchaseOrderRemainingAmount = sum(
    purchaseOrderAmount,
    -thisLineAmount,
    -prevSpent
  );

  return {
    prevSpent,
    thisLineAmount,
    purchaseOrderAmount,
    purchaseOrderIsOverBudget: purchaseOrderRemainingAmount < 0,
    purchaseOrderRemainingAmount: purchaseOrderRemainingAmount,
  };
};

export const getLienWaiverTooltip = (item: LienWaiverItem) => {
  const dateField = LIEN_WAIVER_SIGNED_STATUS.includes(item.status)
    ? item.signed_at
    : item.updated_at;
  const date = formatDate(new Date(dateField));
  return `${item.status_label} on ${date} for ${formatCurrency(item.payment_amount)} payment`;
};
