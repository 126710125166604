import React from "react";
import {
  Button,
  Card,
  dialog,
  Flex,
  Icon,
  Loader,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";

import { useDeleteLienWaiverRequestMutation } from "../api/api";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_REQUESTED_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
  LIEN_WAIVER_STATUS,
  STRINGS,
  TEMPLATE_NOT_REQUESTED_ID,
} from "../constants/constants";
import type { BillLienWaiver, LienWaiverVendor } from "../types";

import { BillLienWaiverFieldCombobox } from "./bill-lien-waiver-field-combobox";
import { BillLienWaiverFieldRequested } from "./bill-lien-waiver-field-requested";
import { BillLienWaiverFieldSigned } from "./bill-lien-waiver-field-signed";
import { BillLienWaiverMarkSignedDialog } from "./bill-lien-waiver-mark-signed-dialog";
import { BillLienWaiverPreviewDrawer } from "./bill-lien-waiver-preview-drawer";
import { BillLienWaiverRequestDialog } from "./bill-lien-waiver-request-dialog";

export type BillLienWaiverFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  recipientEmail?: string;
  vendor: LienWaiverVendor;
  paymentAmount?: number;
  billId?: string | number;
  billLienWaiver?: BillLienWaiver;
  onRequestUpdate: () => void;
};

export const BillLienWaiverField = ({
  value,
  onChange,
  recipientEmail,
  vendor,
  paymentAmount,
  billId,
  billLienWaiver,
  onRequestUpdate,
}: BillLienWaiverFieldProps) => {
  const previewDrawer = useDialog({ lazy: true });
  const requestDialog = useDialog({ lazy: true });
  const uploadSignatureDialog = useDialog({ lazy: true });

  const [deleteLienWaiverRequest, { isLoading }] =
    useDeleteLienWaiverRequestMutation();

  const isValidValue = !!value && value !== TEMPLATE_NOT_REQUESTED_ID;

  const billLienWaiverStatus =
    !billLienWaiver ||
    !LIEN_WAIVER_LINKED_STATUS.includes(billLienWaiver.status)
      ? undefined
      : billLienWaiver.status;

  const isUnrequested = !!billId && !billLienWaiverStatus;
  const isLinkedStatus = !!billLienWaiverStatus && !!billLienWaiver;
  const isRequested =
    isLinkedStatus &&
    LIEN_WAIVER_REQUESTED_STATUS.includes(billLienWaiverStatus);
  const isSigned =
    isLinkedStatus && LIEN_WAIVER_SIGNED_STATUS.includes(billLienWaiverStatus);

  const previewPDF = isRequested
    ? billLienWaiver?.fileExport.document
    : billLienWaiver?.pdf;

  const onDelete = () => {
    if (!billLienWaiver?.id) return;

    dialog.confirmation({
      title: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TITLE,
      message: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TEXT,
      action: {
        primary: {
          children: STRINGS.DELETE,
          color: "error",
          onClick: async () => {
            try {
              await deleteLienWaiverRequest({ id: billLienWaiver?.id });
              toast.success(STRINGS.LIEN_WAIVER_REQUEST_DELETED);
              onRequestUpdate();
            } catch (error) {
              handleErrors(error);
            }
          },
        },
      },
    });
  };

  const onDownload = () => {
    toast.info("Not implemented");
  };

  return (
    <Flex width="full" direction="column">
      <Text size="sm">{STRINGS.LIEN_WAIVER}</Text>
      <Card as={Flex} width="full" size="sm" justify="space-between" gap="lg">
        {!billId ? (
          <Flex width="full" height="48px" justify="center" align="center">
            <Loader size="xl" />
          </Flex>
        ) : (
          <>
            {isUnrequested && (
              <BillLienWaiverFieldCombobox
                value={value}
                onChange={onChange}
                paymentAmount={paymentAmount}
                openRequestDialog={requestDialog.show}
                onRequestUpdate={onRequestUpdate}
                billLienWaiver={billLienWaiver}
                billId={billId}
                vendor={vendor}
              />
            )}
            {isRequested && (
              <BillLienWaiverFieldRequested
                billLienWaiver={billLienWaiver}
                onRequestUpdate={onRequestUpdate}
              />
            )}
            {isSigned && (
              <BillLienWaiverFieldSigned
                markedAsSigned={
                  billLienWaiver.status === LIEN_WAIVER_STATUS.MARKED_AS_SIGNED
                }
                vendor={billLienWaiver.vendor.displayName}
                user={billLienWaiver.revisionEvent?.author.fullName}
                time={billLienWaiver.signedAt}
              />
            )}
            <Flex gap="sm" shrink={false}>
              {isSigned ? (
                <Tooltip message={STRINGS.DELETE_SIGNED_LIEN_WAIVER}>
                  <Button
                    color="neutral"
                    variant="ghost"
                    onClick={onDelete}
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : <Icon name="trash" />}
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip message={STRINGS.UPLOAD_SIGNED_LIEN_WAIVER}>
                  <Button
                    color="neutral"
                    variant="ghost"
                    disabled={isUnrequested}
                    onClick={uploadSignatureDialog.show}
                  >
                    <Icon name="signature" />
                  </Button>
                </Tooltip>
              )}

              <Tooltip
                message={
                  isSigned
                    ? STRINGS.VIEW_SIGNED_LIEN_WAIVER
                    : STRINGS.VIEW_LIEN_WAIVER
                }
              >
                <Button
                  color="neutral"
                  variant="ghost"
                  onClick={previewDrawer.show}
                  disabled={
                    (!billLienWaiver?.fileExport.document && !isValidValue) ||
                    isLoading
                  }
                >
                  <Icon name="eye" />
                </Button>
              </Tooltip>

              <Tooltip
                message={
                  isSigned
                    ? STRINGS.DOWNLOAD_SIGNED_LIEN_WAIVER
                    : STRINGS.DOWNLOAD_LIEN_WAIVER
                }
              >
                <Button
                  color="neutral"
                  variant="ghost"
                  onClick={onDownload}
                  disabled={isLoading}
                >
                  <Icon name="download" />
                </Button>
              </Tooltip>
            </Flex>
          </>
        )}
      </Card>
      {previewDrawer.isRendered && !!(value || previewPDF) && (
        <BillLienWaiverPreviewDrawer
          show={previewDrawer.isVisible}
          onClose={previewDrawer.hide}
          value={value}
          paymentAmount={paymentAmount}
          billId={billId}
          pdf={isLinkedStatus ? previewPDF! : undefined}
        />
      )}
      {requestDialog.isRendered && (
        <BillLienWaiverRequestDialog
          show={requestDialog.isVisible}
          onClose={requestDialog.hide}
          recipientEmail={recipientEmail}
          vendor={vendor}
          lienWaiverTemplateId={value}
          paymentAmount={paymentAmount}
          billId={billId}
          onRequestUpdate={onRequestUpdate}
        />
      )}
      {uploadSignatureDialog.isRendered && billLienWaiver && (
        <BillLienWaiverMarkSignedDialog
          onClose={uploadSignatureDialog.hide}
          show={uploadSignatureDialog.isVisible}
          billLienWaiver={billLienWaiver}
          onRequestUpdate={onRequestUpdate}
        />
      )}
    </Flex>
  );
};
